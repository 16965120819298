import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './landing-page.scss';
import TitleLogo from 'components/ui/title-logo/title-logo';
import Curtains from 'components/ui/curtains/curtains';
import CompanyLogos from 'components/ui/company-logos/company-logos';


const LandingPage = ({setShowLandingPage}) => {
	return (
		<div className="LandingPage">
			<div className='LandingPage-backgroundWrapper'>
				<div className='LandingPage-background'>
					<div className='LandingPage-floor'/>
					<div className='LandingPage-curtain'>
						<Curtains status="closed" />
					</div>
					
				</div>
				<div className='LandingPage-titleLogo'>
					<TitleLogo/>
				</div>
			</div>

			<div className='LandingPage-bottomWrapper'>
				<CompanyLogos/>
				<div className='LandingPage-startButton'>
					<Button
						classes={['yellowGradient', 'bold']}
						text={gameUiTexts.start} 
						onClick={() => {setShowLandingPage(false);}}
					/>
				</div>
			</div>
		</div>
	);
};

LandingPage.propTypes = {
	setShowLandingPage: PropTypes.func.isRequired,
};

export default LandingPage;