import React from 'react';
import PropTypes from 'prop-types';
import {getNextStepBtnActionAndParams} from 'helpers/next-step-helper';
import Button from '../button/button';
import './next-step-button.scss';

const NextStepButton = (props) => {
	/* Props */
	const {
		gameStepId, 
		game, 
		groups, 
		goToGameStep, 
		updatePresentationFeedbackStatus, 
		finishPresentingGroup,
		setPopupData,
		updateGame
	} = props;

	/* Get next step btn text, action and params */
	const {nextStepText, nextStepAction, nextStepParams, nextStepClasses} = getNextStepBtnActionAndParams(
		gameStepId, 
		game, 
		groups, 
		goToGameStep, 
		updatePresentationFeedbackStatus,
		finishPresentingGroup,
		setPopupData,
		updateGame
	);

	/* Do not show next step button */
	if (!nextStepAction) return null;
	
	/* Show next step button */
	return (
		<div className="NextStepButton">
			<Button 
				classes={nextStepClasses}
				text={nextStepText} 
				onClick={() => {nextStepAction(...nextStepParams);}} 
			/>
		</div>
	);
};

NextStepButton.propTypes = {
	gameStepId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	goToGameStep: PropTypes.func.isRequired,
	updatePresentationFeedbackStatus: PropTypes.func.isRequired,
	finishPresentingGroup: PropTypes.func.isRequired,
	setPopupData: PropTypes.func.isRequired,
	updateGame: PropTypes.func.isRequired,
};

export default NextStepButton;