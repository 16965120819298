const imagesData = [
	'audience.svg',
	'background-act-1-a.svg',
	'background-act-1-b.png',
	'background-act-2-a.svg',
	'background-act-2-b.png',
	'background-act-3-a.svg',
	'background-act-3-b.png',
	'background-floor.svg',
	'background-glow.png',
	'background-presentation-1.png',
	'background-presentation-2.svg',
	'background-presentation-3.png',
	'background-wall.svg',
	'button-next.svg',
	'button-prev.svg',
	'button-reveal-winner.svg',
	'curtain-left.svg',
	'curtain-right.svg',
	'logo-cgl.svg',
	'logo-dansk-teater.svg',
	'menu-bullet-white.svg',
	'menu-bullet-yellow.svg',
	'radial.png',
	'speech-bubble-left.svg',
	'speech-bubble-right.svg',
	'title-culture.svg',
	'title-theatre.svg',
	'title-logo.svg',
	'cast/actors/andrea_frydenlund.svg',
	'cast/actors/bianca_madsen.svg',
	'cast/actors/chris_braet.svg',
	'cast/actors/elsa_sigurdsdottir.svg',
	'cast/actors/erasmus_holberg.svg',
	'cast/actors/freddy_langhorn.svg',
	'cast/actors/jamilla_ghazali.svg',
	'cast/actors/jennifer_lovloes.svg',
	'cast/actors/john_delman.svg',
	'cast/actors/kit_rex.svg',
	'cast/actors/knud_k.svg',
	'cast/actors/lene_norland.svg',
	'cast/actors/luca_bakir.svg',
	'cast/actors/michael_nguyen.svg',
	'cast/actors/mohammed_badawi.svg',
	'cast/actors/nikita_morgenstjerne.svg',
	'cast/actors/rasmus_havebro.svg',
	'cast/actors/soeren_tal.svg',
	'cast/actors/sofie_hejdrup.svg',
	'cast/instructors/anders_madsen.svg',
	'cast/instructors/denice_bonbon.svg',
	'cast/instructors/helle_kvidrum.svg',
	'cast/instructors/jhoern_hanzennnnn.svg',
	'cast/instructors/louise_lange.svg',
	'cast/instructors/pauline_show.svg',
	'cast/instructors/pelle_halsloes.svg',
	'cast/instructors/victor_vaagen.svg',
	'cast/scenographers/julie_lak.svg',
	'cast/scenographers/lotte_blaamus.svg',
	'cast/scenographers/mads_maalestok.svg',
	'cast/scenographers/magnus_maalestok.svg',
	'cast/scenographers/rebecca_costello.svg',
	'cast/scenographers/sorin_stiftov.svg',
	'cast/scenographers/ulla_vildsen.svg',
	'cast/scenographers/villiam_vilje.svg',
	'groups/background-show-and-people.svg',
	'groups/background-show.svg',
	'groups/background.svg',
	'groups/blaa-kat.svg',
	'groups/det-skaeve-teater.svg',
	'groups/det-soede-teater.svg',
	'groups/monkey-tonkey.svg',
	'groups/skov-teatret.svg',
	'groups/teater-den-glade-ko.svg',
	'icons/icon-cross.svg',
	'icons/icon-download.svg',
	'icons/icon-loading.svg',
	'icons/icon-play.svg',
	'icons/icon-settings.svg',
	'icons/icon-star-filled.svg',
	'icons/icon-star.svg',
];

export {
	imagesData
};