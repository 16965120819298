import { actors, instructors, scenographers } from 'data/cast-data';
import React from 'react';
import './casting-facilitator.scss';
import PropTypes from 'prop-types';
import { checkIfOptionIsSelectedByAnyGroup, checkIfOptionIsSelectedByAnyGroupArrays } from 'helpers/game-helper';
import { gameUiTexts } from 'data/ui-texts';

const CastingFacilitator = ({groups}) => {
	/**
	 * Check if an option has been selected by a group
	 * @param {string} property 
	 * @param {string} value 
	 */
	 const checkIfOptionIsSelected = (property, value) => {
		return checkIfOptionIsSelectedByAnyGroup(property, value, groups);
	};

	/**
	 * Check if an option has been selected by a group, used for options contained in arrays
	 * @param {string} property 
	 * @param {string} value 
	 */
	const checkIfOptionIsSelectedArray = (arrayName, property, value) => {
		return checkIfOptionIsSelectedByAnyGroupArrays(arrayName, property, value, groups);
	};

	return (
		<div className='CastingFacilitator'>
			{/* Instructor List */}
			<div className='CastingFacilitator-listWrapper'>
				<div className='CastingFacilitator-instructorTitle'>
					<span>{gameUiTexts.instructors}</span>
				</div>
				<div className='CastingFacilitator-list'>
					{instructors.map((instructor, index) => {
						const isTaken = checkIfOptionIsSelected('instructorId', instructor.id);
						return (
							<div key={index} className='CastingFacilitator-castPersonWrapper'>
								<div className={'CastingFacilitator-person instructor-' + instructor.id 
									+ (isTaken ? ' taken' : '')}/>
								<span>{instructor.name}</span>
							</div>
						);
					})}
				</div>
			</div>
			
			{/* Scenographer List */}
			<div className='CastingFacilitator-listWrapper'>
				<div className='CastingFacilitator-scenographerTitle'>
					<span>{gameUiTexts.scenographers}</span>
				</div>
				<div className='CastingFacilitator-list'>
					{scenographers.map((scenographer, index) => {
						const isTaken = checkIfOptionIsSelected('scenographerId', scenographer.id);
						return (
							<div key={index} className='CastingFacilitator-castPersonWrapper'>
								<div className={'CastingFacilitator-person scenographer-' + scenographer.id
									+ (isTaken ? ' taken' : '')}/>
								<span>{scenographer.name}</span>
							</div>
						);
					})}
				</div>
			</div>

			{/* Actor Lists */}
			<div className='CastingFacilitator-listWrapper'>
				<div className='CastingFacilitator-actorTitle'>
					<span>{gameUiTexts.actors}</span>
				</div>
				<div className='CastingFacilitator-list tall'>
					{actors.map((actor, index) => {
						const isTaken = checkIfOptionIsSelectedArray('actors', 'actorId', actor.id);
						return (
							<div key={index} className='CastingFacilitator-castPersonWrapper'>
								<div className={'CastingFacilitator-person actor-' + actor.id
									+ (isTaken ? ' taken' : '')}/>
								<span>{actor.name}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

CastingFacilitator.propTypes = {
	groups: PropTypes.array.isRequired,
};


export default CastingFacilitator;