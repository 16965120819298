const cookieUiTexts = {
	popupText: 'Vi bruger cookies til at forbedre din spiloplevelse. Ved at klikke på \'OK\' eller logge ind accepterer du vores brug af cookies.',
	popupBtn: 'Ok',
};

const errorUiTexts = {
	unknownError: 'Fejl: Ukendt fejl.',
	/* Create user (facilitator) */
	emptyFields: 'Du skal udfylde alle felter.',
	emailExists: 'Der findes allerede en bruger med denne email.',
	invalidPassword: 'Dit password skal være på mindst 8 tegn.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	userNotAllowed: 'Denne e-mail er ikke godkendt.',
	/* Login / reset password (facilitator) */
	emailNotFound: 'Der findes ingen bruger med denne email.',
	invalidEmail: 'Din email er ikke gyldig.',
	invalidEmailPasswordFormat: 'Fejl. Check om din email og password er korrekte.',
	/* Login (group) */
	noGameCodeMatch: 'Ugyldig spilkode.',
	/* Create group */
	invalidCharacters: 'Navnet indeholder et eller flere ugyldige tegn.',
	groupNameTooShort: 'Gruppenavn skal være på mindst 3 tegn.',
	groupNameTooLong: 'Gruppenavn må ikke være længere end 12 tegn.',
	noGroupName: 'Husk at udfylde et gruppenavn.',
	groupNameAlreadyExists: 'Der eksisterer allerede en gruppe med det navn.',

};

const loginUiTexts = {
	loading: 'Loading',
	facilitatorLogin: 'Hvis du er lærer',
	facilitatorResetPw: 'Nulstil password',
	facilitatorCreateUser: 'Opret ny bruger',
	groupLogin: 'Hvis du er elev',
	gameCode: 'Spilkode',
	login: 'Log ind',
	newGroup: 'Ny gruppe',
	groupName: 'Gruppenavn',
	create: 'Opret',
	cancel: 'Afbryd',
	email: 'E-mail',
	password: 'Password',
	repeatPassword: 'Gentag password',
	logout: 'Log ud',
	createNewUser: 'Opret ny bruger',
	create: 'Opret',
	newUserCreated: 'Din bruger er blevet oprettet, du kan nu logge ind.',
	backToLogin: 'Tilbage til login',
	resetPassword: 'Nulstil password',
	reset: 'Nulstil',
	passwordIsReset: 'Du har fået tilsendt en e-mail med et link til at nulstille dit password.',
	loggedInAs: 'Logget ind som',
	useGameCode: 'Log ind med spilkode',
	loggingIn: 'Logger ind ...',
	loadingGame: 'Henter spil ...',
};

const facilitatorUiTexts = {
	
	settingsPopup: {
		title: 'Indstillinger',
		restartGameBtn: 'Genstart spil',
	},

	downloadsPopup: {
		title: 'Download lærermateriale',
		manual: 'Lærervejledning',
		tableCards: 'Bordskilte',
		cast: 'Aktører'
	}
};

const gameUiTexts = {
	gameTitle: 'Sæt Scenen',
	back: 'Tilbage',
	choose: 'Vælg',
	confirm: 'ok',
	gameCode: 'Spilkode',
	startUp: 'Opstart',
	finish: 'Afslutning',
	genre: 'Genre',
	as: 'Som',
	next: 'Videre',
	evaluation: 'Bedømmelse',
	restartGame: 'Nulstil spil',
	downloadMaterials: 'Download lærermateriale',
	downloadManusExample: 'Download manuskripteksempel',
	showAndGenre: 'Forestilling og genre',
	show: 'Forestilling',
	start: 'Start',
	toFacilitator: 'Til læreren',
	instructor: 'Instruktør',
	instructors: 'Instruktører',
	instructedBy: 'Instrueret af',
	scenographer: 'Scenograf',
	scenographers: 'Scenografer',
	scenographyBy: 'Med scenografi af',
	actors: 'Skuespillere',
	with: 'Med',
	chooseRole: 'Vælg rolle',
	whichRole: 'Hvilken rolle skal %ACTOR% spille?',
	reviews: 'Anmeldelser',
	showWinner: 'Afslør årets forestilling',
	culture: 'Kultur',
	theatre: 'Teater',
	star: 'Stjerne',
	presentationWarning: `<br /><br /><b>Bemærk!</b> Da der ikke er nogen grupper logget ind, kan du ikke se denne del af spillet.`,
	groupPropertyErrorPopup: {
		title: 'Hov ... !!!',
		text: `En anden gruppe har allerede valgt %PROPERTY%.`,
		button: 'Tilbage'
	},

	groupsNotDoneChoosingWarningPopup: {
		title: 'Hov ... !!!',
		text: `Følgende grupper mangler at vælge:`,
		button: 'Tilbage'
	},
	
	presentationWarningPopup: {
		title: 'Hov ... !!!',
		text1: `Du mangler at stemme.`,
		text2: `Følgende grupper mangler at stemme:`,
		button: 'Tilbage'
	},

	review: {
		culture: 'Kultur',
		theatre: 'Teater',
		info: `Spiller alle hverdage kl. 20.00
			<br />Weekenden kl. 14.00 og kl. 20.00.
			<br />Billetter på www.billetnet.dk
			<br />Priser fra 60,- til 440,-.`
	}
};

const statsUiTexts = {
	loadingStats: 'Henter statistik ...',
	stats: 'Statistik',
	backToGame: 'Tilbage til spillet',
	numberOfUsers: 'Antal oprettede brugere',
	numberOfFinishedTestGames: 'Antal gennemførte preview',
	numberOfStartedGames: 'Antal startede spil med elever',
	numberOfFinishedGames: 'Antal afsluttede spil med elever',
	email: 'E-mail',
	created: 'Oprettet',
	startedGames: 'Startede spil med elever',
	finishedGames: 'Afsluttede spil med elever',
	finishedTestGames: 'Gennemførte preview'
}

export {
	cookieUiTexts,
	errorUiTexts,
	loginUiTexts,
	facilitatorUiTexts,
	gameUiTexts,
	statsUiTexts
};