import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { gameUiTexts } from 'data/ui-texts';
import {actsData} from 'data/acts-data';
import {gameStepsData} from 'data/game-steps-data';
import './menu.scss';

const Menu = ({gameStepId, goToGameStep}) => {
	/* Get current act from game step id */
	const currentActId = gameStepsData.find((gs) => {return gs.id === gameStepId;}).actId;
	const currentActIndex = (currentActId 
		? actsData.findIndex((ad) => {return ad.id === currentActId;})
		: gameStepId === gameStepsData[0].id ? 0 : 4
	);

	/* Check if facilitator can use menu to navigate */
	const menuIsNavigationable = (appConfig.env !== 'demo' && appConfig.env !== 'production' ? true : false);
	
	return (
		<div className="Menu">
			<div className="Menu-items">
				{/* Game start (group login) */ }
				<div className="Menu-startOrFinish start">
					<div className="Menu-startOrFinishTitle">{gameUiTexts.startUp}</div>
					<div 
						className={'Menu-gameStep' + (menuIsNavigationable ? ' active' : '')}
						onClick={() => {if (menuIsNavigationable) goToGameStep(gameStepsData[0].id);}}
					><span>{gameStepsData[0].title}</span>
					</div>
				</div>
				{/* Acts */}
				{actsData.map((act, actIndex) => {
					const isFutureAct = actIndex > currentActIndex;
					const gameStepsInAct = gameStepsData.filter((gs) => {return gs.actId === act.id;});
					return (
						<div key={actIndex} className={'Menu-act' + (isFutureAct ? ' future' : '')}>
							<div className="Menu-actTitle">{act.title}</div>
							<div className="Menu-actSubtitle">{act.subtitle}</div>
							{!isFutureAct && gameStepsInAct.map((gameStep, gameStepIndex) => {
								const isFutureGameStep = (
									actIndex === currentActIndex &&
									gameStepIndex > gameStepsInAct.findIndex((gs) => {return gs.id === gameStepId;})
								);
								return (
									<div 
										key={gameStep.id} 
										className={'Menu-gameStep' + (isFutureGameStep ? ' future' : '' )
											+ (menuIsNavigationable ? ' active' : '')}
										onClick={() => {if (menuIsNavigationable) goToGameStep(gameStep.id);}}
									>
										<span>{gameStep.title}</span>
									</div>
								);
							})}
						</div>
					);
				})}
				{/* Game finish (reviews) */}
				<div className={'Menu-startOrFinish finish' 
					+ (gameStepId !== gameStepsData[gameStepsData.length - 1].id ? ' future' : '')}
				>
					<div className="Menu-startOrFinishTitle">{gameUiTexts.finish}</div>
					<div 
						className={'Menu-gameStep' + (menuIsNavigationable ? ' active' : '')
							+ (gameStepId !== gameStepsData[gameStepsData.length - 1].id ? ' future' : '')
						}
						onClick={() => {
							if (menuIsNavigationable) goToGameStep(gameStepsData[gameStepsData.length - 1].id);
						}}
					><span>{gameStepsData[gameStepsData.length - 1].title}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

Menu.propTypes = {
	gameStepId: PropTypes.string.isRequired,
	goToGameStep: PropTypes.func.isRequired
};

export default Menu;