const groupsData = [
	{
		id: 'teater-den-glade-ko',
		color: '#FACA47',
		name: 'Teater Den Glade Ko',
		description: 'Vi kombinerer teater med spændende teknologier og computerspil.'
	},
	{
		id: 'det-soede-teater',
		color: '#D8016B',
		name: 'Det Søde Teater',
		description: 'Vi kan godt lide kærlighedshistorier, prinsesser og flotte kostumer.'
	},
	{
		id: 'skov-teatret',
		color: '#7FB979',
		name: 'Skovteatret',
		description: 'Vores teater ligger ude i skoven, og vi bruger tit planter og dyr på scenen.'
	},
	{
		id: 'blaa-kat',
		color: '#0089D9',
		name: 'Blå Kat',
		description: 'Vi elsker at lave store shows, gerne med masser af musik og dans.'
	},
	{
		id: 'monkey-tonkey',
		color: '#EF001F',
		name: 'Monkey Tonkey',
		description: 'Vi har tit akrobatik, stunts og slåskampe på scenen.'
	},
	{
		id: 'det-skaeve-teater',
		color: '#793D90',
		name: 'Det Skæve Teater',
		description: 'Vi kan godt lide at overraske vores publikum med jokes og pranks.'
	}
];

export {
	groupsData
};