import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {gameStepsData} from 'data/game-steps-data';
import Button from '../button/button';
import './prev-step-button.scss';

const PrevStepButton = (props) => {
	/* Props */
	const {
		gameStepId, 
		goToGameStep
	} = props;

	/* Get index of current game step */
	const currentGameStepIndex = gameStepsData.findIndex((gs) => {return gs.id === gameStepId;});

	/* Get prev game step id */
	const prevGameStepId = (currentGameStepIndex > 0 ? gameStepsData[currentGameStepIndex - 1].id : null);

	/* Do not show prev step button */
	if (!prevGameStepId) return null;
	
	/* Show prev step button */
	return (
		<div className="PrevStepButton">
			<Button 
				classes={['prev']}
				text={gameUiTexts.back} 
				onClick={() => {goToGameStep(prevGameStepId);}} 
			/>
		</div>
	);
};

PrevStepButton.propTypes = {
	gameStepId: PropTypes.string.isRequired,
	goToGameStep: PropTypes.func.isRequired
};

export default PrevStepButton;