import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {gameStepsData} from 'data/game-steps-data';
import {genresData} from 'data/genres-data';
import {showsData} from 'data/shows-data';
import {gameUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {checkIfOptionIsSelectedByAnyGroup} from 'helpers/game-helper';
import Button from 'components/ui/button/button';
import './choose-show.scss';

const ChooseShow = (props) => {
	/* Props */
	const {
		gameStepId,
		updateGroup,
		setPopupData,
		group,
		groups,
		updateSpecialGroupProperty
	} = props;

	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	/**
	 * Check if an option has been selected by a group
	 * @param {string} property 
	 * @param {string} value 
	 */
	 const checkIfOptionIsSelected = useCallback((property, value) => {
		return checkIfOptionIsSelectedByAnyGroup(property, value, groups);
	}, [groups]);

	/* Show loading icon if chossing show/genre */
	const [isChoosingShowId, setIsChoosingShowId] = useState(null);
	const [isChoosingGenreId, setIsChoosingGenreId] = useState(null);

	/**
	 * Select option
	 * @param {string} optionType 
	 * @param {string} optionId 
	 */
	const handleSelectOption = (optionType, optionId) => {
		/* Return if already opdating group choice */
		if (isChoosingGenreId || isChoosingShowId) return null;

		/* Genre: update group directly */
		if (optionType === 'genreId') {
			setIsChoosingGenreId(optionId);
			updateGroup({[optionType]: optionId}).then(() => {
				setIsChoosingGenreId(null);
			});
		}

		/* Show: check if option is available */
		if (optionType === 'showId') {
			setIsChoosingShowId(optionId);
			updateSpecialGroupProperty(optionType, optionId).then((response) => {
				if (response.status === 'success') {
					/* Option selected, dismiss loading icon */
					setIsChoosingShowId(null);
				} else {
					/* Option not selected, another group has already selected it */
					let optionData = showsData.find((s) => {return s.id === optionId;});
	
					/* Show popup */
					let popupText = JSON.parse(JSON.stringify(gameUiTexts.groupPropertyErrorPopup.text));
					popupText = popupText.replace('%PROPERTY%', optionData.value);
					const popupData = {
						title: gameUiTexts.groupPropertyErrorPopup.title,
						text: popupText,
						buttons: [{
							text: gameUiTexts.groupPropertyErrorPopup.button,
							classes: ['prev'],
							action: setPopupData,
							parameters: [null]
						}],
					};
					setPopupData(popupData);
					setIsChoosingShowId(null);
				}
			});
		}
	};

	return (
		<div className="ChooseShow">
			{/* Title and info */}
			<div className='ChooseShow-info'>
				<div className='ChooseShow-infoTitle'>
					<span>
						{gameUiTexts.showAndGenre}
					</span>
				</div>
				<div className='ChooseShow-infoTextWrapper'>
					<div className='ChooseShow-infoText'>
						{renderMarkdown(gameStepData.groupInstructions)}
					</div>
				</div>
			</div>
			<div className='ChooseShow-choiceWrapper'>
				{/* Shows */}
				<div className='ChooseShow-shows'>
					<div className='ChooseShow-showTitle'>
						<span>{gameUiTexts.show}</span>
					</div>
					<div className='ChooseShow-showsWrapper'>
						{showsData.map((show, index) => {
							const isSelectedByGroup = (group.showId === show.id);
							const isSelectedByAnotherGroup = (
								!isSelectedByGroup && 
								checkIfOptionIsSelected('showId', show.id)
							);

							let buttonClasses = ['offWhite'];
							if (isSelectedByGroup) buttonClasses.push('confirmed');
							if (isSelectedByAnotherGroup) buttonClasses.push('disabled');							

							return (
								<div key={index} className='ChooseShow-show'>
									<Button
										isLoading={isChoosingShowId === show.id}
										isDisabled={isSelectedByAnotherGroup}
										classes={buttonClasses}
										text={show.value}
										onClick={() => {handleSelectOption('showId', show.id);}}
									/>
								</div>
							);
						})}
					</div>
				</div>
				{/* Genres */}
				<div className='ChooseShow-genres'>
					<div className='ChooseShow-genreTitle'>
						<span>{gameUiTexts.genre}</span>
					</div>
					{genresData.map((genre, index) => {
						const isSelectedByGroup = (group.genreId === genre.id ? ' selected' : '');
						let buttonClasses = ['offWhite'];
						if (isSelectedByGroup) buttonClasses.push('confirmed');

						return (
							<div key={index} className='ChooseShow-genre'>
								<Button
									classes={buttonClasses}
									text={genre.value}
									onClick={() => {handleSelectOption('genreId', genre.id);}}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

ChooseShow.propTypes = {
	gameStepId: PropTypes.string.isRequired,
	updateGroup: PropTypes.func.isRequired,
	setPopupData: PropTypes.func.isRequired,
	group: PropTypes.object.isRequired,
	groups: PropTypes.array.isRequired,
	updateSpecialGroupProperty: PropTypes.func.isRequired
};

export default ChooseShow;