import React from 'react';
import './attention.scss';
import PropTypes from 'prop-types';
import { gameStepsData } from 'data/game-steps-data';

const Attention = ({gameStepId}) => {
	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	return (
		<div className={'Attention ' + (gameStepData.actId)}>
			<div className='Attention-title'>
				<span>{gameStepData.groupInfo}</span>
			</div>
		</div>
	);
};

Attention.propTypes = {
	gameStepId: PropTypes.string.isRequired
};

export default Attention;