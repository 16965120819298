const roles = [
	'actor',
	'scenographer',
	'instructor'
];

const actors = [
	{
		id: 1,
		name: 'Chris Bræt',
		role: roles[0]
	},
	{
		id: 2,
		name: 'Jennifer Lovløs',
		role: roles[0],
	},
	{
		id: 3,
		name: 'Søren Tal',
		role: roles[0],
	},
	{
		id: 4,
		name: 'Sofie Hejdrup',
		role: roles[0],
	},
	{
		id: 5,
		name: 'Bianca Madsen',
		role: roles[0],
	},
	{
		id: 6,
		name: 'Lene Norland',
		role: roles[0],
	},
	{
		id: 7,
		name: 'Erasmus Von Holberg',
		role: roles[0],
	},
	{
		id: 8,
		name: 'Rasmus Havebro',
		role: roles[0],
	},
	{
		id: 9,
		name: 'John Delman',
		role: roles[0],
	},
	{
		id: 10,
		name: 'Thor Mølner',
		role: roles[0],
	},
	{
		id: 11,
		name: 'Luca Bakir',
		role: roles[0],
	},
	{
		id: 12,
		name: 'Knud Knud',
		role: roles[0],
	},
	{
		id: 13,
		name: 'Michael Nguyen',
		role: roles[0],
	},
	{
		id: 14,
		name: 'Jamilla Ghazali',
		role: roles[0],
	},
	{
		id: 15,
		name: 'Mohammed Badawi',
		role: roles[0],
	},
	{
		id: 16,
		name: 'Elsa Sigurdsdottir',
		role: roles[0],
	},
	{
		id: 17,
		name: 'Andrea Frydenlund',
		role: roles[0],
	},
	{
		id: 18,
		name: 'Nikita Morgenstjerne',
		role: roles[0],
	},
	{
		id: 19,
		name: 'Freddy Langhorn',
		role: roles[0],
	},
	{
		id: 20,
		name: 'Kit Rex',
		role: roles[0],
	},
];

const scenographers = [
	{
		id: 1,
		name: 'Julie Lak',
		role: roles[1],
	},
	{
		id: 2,
		name: 'Lotte Blåmus',
		role: roles[1],
	},
	{
		id: 3,
		name: 'Magnus Målestok',
		role: roles[1],
	},
	{
		id: 4,
		name: 'Mads Målestok',
		role: roles[1],
	},
	{
		id: 5,
		name: 'Ulla Vildsen',
		role: roles[1],
	},
	{
		id: 6,
		name: 'Devarîk M. Vilje',
		role: roles[1],
	},
	{
		id: 7,
		name: 'Rebecca Costello',
		role: roles[1],
	},
	{
		id: 8,
		name: 'Sorin Stiftov',
		role: roles[1],
	},
];

const instructors = [
	{
		id: 1,
		name: 'Helle Kvidrum',
		role: roles[2],
	},
	{
		id: 2,
		name: 'Jhørn Hanzennnnn',
		role: roles[2],
	},
	{
		id: 3,
		name: 'Anders Madsen',
		role: roles[2],
	},
	{
		id: 4,
		name: 'Pauline Show',
		role: roles[2],
	},
	{
		id: 5,
		name: 'Louise Lange',
		role: roles[2],
	},
	{
		id: 6,
		name: 'Pelle Halsløs',
		role: roles[2],
	},
	{
		id: 7,
		name: 'Denice De la Bonbon',
		role: roles[2],
	},
	{
		id: 8,
		name: 'Victor Vågen',
		role: roles[2],
	}
];

export {
	roles,
	actors,
	instructors,
	scenographers,
};