import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {getPresentationData, getSelectedFeedbackIds, getFeedbackResult} from 'helpers/presentation-helper';
import {gameStepsData} from 'data/game-steps-data';
import GroupBadge from 'components/ui/group-badge/group-badge';
import {ReactComponent as SpeechBubbleLeft} from 'assets/images/speech-bubble-left.svg';
import {ReactComponent as SpeechBubbleRight} from 'assets/images/speech-bubble-right.svg';
import './presentation.scss';

const Presentation = (props) => {
	/* Props */
	const {
		isFacilitator, 
		gameStepId, 
		game, 
		group,
		groups, 
		setCurtainStatus,
		selectGroupForPresentation,
		updateSelectedFeedbackIds
	} = props;

	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	/* Presentation data */
	const presentationData = getPresentationData(gameStepData.actId, game);
	

	/* Presenting group id */
	const selectedGroupData = (presentationData && presentationData.selectedGroupId 
		?	groups.find((g) => {return g.id === presentationData.selectedGroupId;})
		: null
	);

	/* Selected feedback ids */
	const selectedFeedbackIds = getSelectedFeedbackIds(isFacilitator, gameStepData.actId, presentationData, group);

	/* Feedback result */
	const feedbackResult = getFeedbackResult(
		gameStepData.actId, 
		gameStepData.feedbackOptions, 
		presentationData, 
		groups
	);

	/* Get limits for feedback result */
	let maxSelectedFeedback = 0;
	let minSelectedFeedback = null;
	if (feedbackResult && feedbackResult.length > 0) {
		feedbackResult.forEach((feedback) => {
			if (feedback.selectedBy > maxSelectedFeedback) {
				maxSelectedFeedback = feedback.selectedBy;
			}
			if (minSelectedFeedback === null || feedback.selectedBy < minSelectedFeedback) {
				minSelectedFeedback = feedback.selectedBy;
			}
		});
	}

	/**
	 * Select / deselect feedback option
	 * @param {string} feedbackId 
	 */
	const handleToggleFeedbackId = (feedbackId) => {
		if (selectedFeedbackIds.indexOf(feedbackId) >= 0 || selectedFeedbackIds.length < 3) {
			updateSelectedFeedbackIds(gameStepData.actId, selectedGroupData.id, feedbackId);
		}
	};

	/**
	 * Get curtains status (depends on where we are in the presentation)
	 * @returns 
	 */
	const updateCurtainStatus = () => {
		let curtainStatus = 'open';
		if (selectedGroupData && presentationData && presentationData.feedbackStatus !== 'ready') {
			curtainStatus = 'open-wide';
		}
		setCurtainStatus(curtainStatus);
		return curtainStatus;
	};


	/**
	 * Adjust curtain status so that it matches where we are in the presentation
	 */
	useEffect(() => {
		if (gameStepId === game.gameStepId) {
			updateCurtainStatus();
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [presentationData]);

	return (
		<div className={'Presentation ' + gameStepData.actId + (selectedGroupData ? '' : ' showBackGround')}>
			<div className={'Presentation-light ' + (presentationData ? '' : 'away')}>
				<div className={'triangle-left ' + (presentationData ? 
					presentationData.feedbackStatus : 'away')}/>
				<div className={'triangle-right ' + (presentationData ? 
					presentationData.feedbackStatus : 'away')}/>
			</div>
			{/* All active groups */}
			<div className="Presentation-groups">
				{groups.map((group) => {
					if (!group.isPlaying) return null;

					/* Check if group already has been presented */
					const groupHasBeenPresented = (
						presentationData && 
						presentationData.selectedGroupId !== group.id && 
						presentationData.hasOwnProperty('finishedGroupIds') &&
						presentationData.finishedGroupIds.indexOf(group.id) >= 0
					);

					/* Check if group faded (already presented or in background during presentation)
						 or selectable (only if user is facilitator and group has not been presented yet) 
					*/
					const groupIsFaded = (groupHasBeenPresented || selectedGroupData);
					const groupIsHidden = (
						(selectedGroupData && selectedGroupData.id === group.id) ||
						(selectedGroupData && presentationData && presentationData.feedbackStatus === 'closed')
					);
					const groupIsSelectable = (isFacilitator && !groupIsFaded);

					return (
						<div 
							key={group.id} 
							className={'Presentation-group' 
								+ (groupIsFaded ? ' faded' : '') 
								+ (groupIsHidden ? ' hide' : '')
								+ (groupIsSelectable ? ' selectable' : '')}
							onClick={() => {
								if (groupIsSelectable) selectGroupForPresentation(gameStepData.actId, group.id);
							}}
						>
							<GroupBadge type="presentation" group={group} />
						</div>
					);
				})}
			</div>

			{/* Selected group (group badge and spot lights */}
			{selectedGroupData && 
				<div 
					className={'Presentation-selectedGroup' 
						+ (presentationData.feedbackStatus ? ' ' + presentationData.feedbackStatus : '')}
				>
					<div className={'Presentation-selectedGroupBadge ' + gameStepData.actId}>
						<GroupBadge type="presentation-focus" actId={gameStepData.actId} group={selectedGroupData} />
					</div>
				</div>
			}

			{/* Feedback options */}
			{(
				selectedGroupData && 
				presentationData.feedbackStatus === 'open' &&
				(isFacilitator || group.id !== selectedGroupData.id)
			) &&
				<div className="Presentation-feedbackOptions">
					{gameStepData.feedbackOptions.map((feedback, index) => {
						const isSelected = selectedFeedbackIds.indexOf(feedback.id) >= 0;
						return (
							<div 
								key={feedback.id} 
								className={'Presentation-feedbackOption ' + selectedGroupData.groupId 
									+ ' ' + gameStepData.actId
									+ ' feedback-' + feedback.id
									+ (isSelected ? ' selected' : '')
								}
								onClick={() => {handleToggleFeedbackId(feedback.id);}}
							>
								{[0, 1, 4, 5].indexOf(index) >= 0 
									? <SpeechBubbleLeft />
									:	<SpeechBubbleRight />
								}
								<span>{feedback.text}</span>
							</div>
						);
					})}
				</div>
			}

			{/* Feedback results */}
			{(selectedGroupData && feedbackResult.length > 0) &&
				<div className="Presentation-feedbackResults">
					{feedbackResult.map((feedback) => {
						/* Get feedback data */
						const feedbackData = gameStepData.feedbackOptions.find((fo) => {return fo.id === feedback.id;});

						/* Translate feedback to scale from 0.9 (least votes) to 1.6 (most votes) */
						let scale = 1;
						if (maxSelectedFeedback > minSelectedFeedback) {
							scale = ((
								(feedback.selectedBy - minSelectedFeedback) / 
								(maxSelectedFeedback - minSelectedFeedback)
							)  * (1.6 - 0.9)) + 0.9;
						}
						return (
							<div 
								key={feedback.id}
								style={{'--size': scale}}
								className={'Presentation-feedbackResult ' + selectedGroupData.groupId 
									+ ' feedback-' + feedback.id
									+ ' ' + gameStepData.actId
								}
							>
								<SpeechBubbleRight />
								<span>{feedbackData.text}</span>
								<span>{feedback.selectedBy}</span>
							</div>
						);
					})}
				</div>
			}
		</div>
	);
};

Presentation.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	gameStepId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	groups: PropTypes.array,
	setCurtainStatus: PropTypes.func,
	selectGroupForPresentation: PropTypes.func,
	updateSelectedFeedbackIds: PropTypes.func,
};

export default Presentation;