const genresData = [
	{
		id: 1,
		value: 'Thriller'
	},
	{
		id: 2,
		value: 'Musical'
	},
	{
		id: 3,
		value: 'Fysisk teater'
	},
	{
		id: 4,
		value: 'Komedie'
	},
	{
		id: 5,
		value: 'Tragedie'
	},
	{
		id: 6,
		value: 'Kærlighedshistorie'
	}
];
export {
	genresData
};