import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {actsData} from 'data/acts-data';
import {gameStepsData} from 'data/game-steps-data';
import YouTube from 'react-youtube';
import './intro.scss';

const Intro = ({gameStepId}) => {
	/* Video state and ref */
	const [videoState, setVideoState] = useState('loading');
	const [videoRef, setVideoRef] = useState(null);

	/* Play video */
	const handlePlay = (e) => {
		e.stopPropagation();
		if (videoState === 'loading') return;
		if (videoRef && videoRef.playVideo)  videoRef.playVideo();
	};

	const videoEnded = () => {
		setVideoState('ended');

		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	};

	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});
	const actData = actsData.find((a) => {return a.id === gameStepData.actId;});
	
	return (
		<div className={'Intro ' + gameStepData.actId}>
			<div className="Intro-title">{actData.subtitle}</div>
			{gameStepData.videoId && 
				<div className="Intro-videoWrap">
					<YouTube 
						className={'Intro-video ' + videoState} 
						videoId={gameStepData.videoId} 
						onReady={(e) => {setVideoRef(e.target); setVideoState('ready');}}
						onPlay={() => {setVideoState('playing');}}
						onEnd={() => {videoEnded();}}
						opts={{
							height: '100%', 
							width: '100%', 
							playerVars: {
								controls: 1,
							}
						}}
					/>
					<div 
						className={'Intro-videoPlayBtn ' + videoState} 
						onClick={(e) => {handlePlay(e);}} 
					/>
				</div>
			}
		</div>
	);
};

Intro.propTypes = {
	gameStepId: PropTypes.string.isRequired
};

export default Intro;