const showsData = [
	{
		id: 1,
		value: 'Brødrene Løvehjerte',
		roles: [
			{
				id: 1,
				title: 'Karl Tvebak'
			},
			{
				id: 2,
				title: 'Jonathan'
			},
			{
				id: 3,
				title: 'Sofie'
			}
		]
	},
	
	{
		id: 2,
		value: 'Den grimme ælling',
		roles: [
			{
				id: 1,
				title: 'Den grimme ælling'
			},
			{
				id: 2, 
				title: 'Andemor'
			},
			{
				id: 3,
				title: 'Den gamle kone'
			}

		]
	},
	{
		id: 3,
		value: 'Klods Hans',
		roles: [
			{
				id: 1,
				title: 'Klods Hans'
			},
			{
				id: 2,
				title: 'En af brødrene'
			},
			{
				id: 3,
				title: 'Prinsessen'
			}
		]
	},
	{
		id: 4,
		value: 'Løvernes konge',
		roles: [
			{
				id: 1, 
				title: 'Simba'
			},
			{
				id: 2,
				title: 'Timon'
			},
			{
				id: 3,
				title: 'Pumba'
			}
		]
	},
	{
		id: 5,
		value: 'Frost',
		roles: [
			{
				id: 1,
				title: 'Elsa'
			}, 
			{
				id: 2,
				title: 'Anna'
			},
			{
				id: 3,
				title: 'Olaf'
			}
		]
	},
	{
		id: 6,
		value: 'Spiderman',
		roles: [
			{
				id: 1,
				title: 'Spiderman'
			},
			{
				id: 2,
				title: 'MJ (Mary Jane)'
			},
			{
				id: 3,
				title: 'Green Goblin'
			}
		]
	},
	{
		id: 7,
		value: 'Star Wars',
		roles: [
			{
				id: 1,
				title: 'Luke Skywalker'
			},
			{
				id: 2,
				title: 'Princess Leia'
			},
			{
				id: 3,
				title: 'Darth Vader'
			}
		]
	},
	{
		id: 8,
		value: 'Harry Potter',
		roles: [
			{
				id: 1,
				title: 'Harry Potter'
			},
			{
				id: 2,
				title: 'Hermione Granger'
			},
			{
				id: 3,
				title: 'Dolores Nidkjær'
			}
		]
	}
];

export {
	showsData
};