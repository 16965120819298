import React, {useState} from 'react';
import LoginFacilitatorController from './login-facilitator-controller';
import ResetPassword from './reset-password';
import CreateUserController from './create-user-controller';
import LoginGroupController from './login-group-controller';
import CookieConsentPopup from 'components/ui/cookie-consent-popup/cookie-consent-popup';
import './login.scss';
import Curtains from 'components/ui/curtains/curtains';
import TitleLogo from 'components/ui/title-logo/title-logo';
import CompanyLogos from 'components/ui/company-logos/company-logos';

const Login = () => {
	/* Get active box component for facilitator box */
	let [facilitatorBoxType, setFacilitatorBoxType] = useState('login');
	let FacilitatorBoxComponent = LoginFacilitatorController;
	if (facilitatorBoxType === 'createUser') FacilitatorBoxComponent = CreateUserController;
	if (facilitatorBoxType === 'resetPassword') FacilitatorBoxComponent = ResetPassword;

	return (
		<div className="Login">
			<Curtains status='closed'/>
			<div className='Login-logos'>
				<CompanyLogos/>
			</div>
			<div className='Login-logo'>
				<TitleLogo hasRadial={false}/>
			</div>
			<div className="Login-wrap">
				<div className="Login-facilitator">
					<FacilitatorBoxComponent setFacilitatorBoxType={setFacilitatorBoxType} />
				</div>
				<div className="Login-group">
					<LoginGroupController />
				</div>
			</div>
			<CookieConsentPopup />
		</div>
	);
};



export default Login;