import React, {useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {actors, instructors, scenographers} from 'data/cast-data';
import {showsData} from 'data/shows-data';
import {gameStepsData} from 'data/game-steps-data';
import {gameUiTexts} from 'data/ui-texts';
import {checkIfOptionIsSelectedByAnyGroup, checkIfOptionIsSelectedByAnyGroupArrays } from 'helpers/game-helper';
import CastingPopup from './casting-popup';
import './casting.scss';

const Casting = (props) => {
	/* Props */
	const {
		gameStepId,
		group,
		groups,
		updateGroup,
		updateSpecialGroupProperty
	} = props;

	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	/* Selected show data */
	const selectedShowData = showsData.find((s) => {return s.id === group.showId;});

	/* Selected cast data */
	const selectedInstructor = (group.instructorId 
		? instructors.find((i) => {return i.id === group.instructorId;})
		: null
	);
	const selectedScenographer = (group.scenographerId 
		? scenographers.find((i) => {return i.id === group.scenographerId;})
		: null
	);

	/* Track if updating selected cast */
	const [updatingSelection, setUpdatingSelection] = useState(null);	

	/* Popup: select role for actor */
	const [popupActorId, setPopupActorId] = useState(null);

	/**
	 * Check if an option has been selected by a group
	 * @param {string} property 
	 * @param {string} value 
	 */
	 const checkIfOptionIsSelected = useCallback((property, value) => {
		return checkIfOptionIsSelectedByAnyGroup(property, value, groups);
	}, [groups]);

	/**
	 * Check if an option has been selected by a group, used for options contained in arrays
	 * @param {string} property 
	 * @param {string} value 
	 */
	const checkIfOptionIsSelectedArray = useCallback((arrayName, property, value) => {
		return checkIfOptionIsSelectedByAnyGroupArrays(arrayName, property, value, groups);
	}, [groups]);

	/**
	 * Select / deselect actor
	 * @param {bool} isTaken 
	 * @param {number} actorId 
	 */
	const handleSelectActorAndRole = (isTaken, actorId) => {
		if (!isTaken) {
			/* Get already selected actors */
			const currentActorChoices = group.actors ? JSON.parse(JSON.stringify(group.actors)) : [];

			const actorIndex = currentActorChoices.findIndex((a) => {return a.actorId === actorId;});
			if (actorIndex >= 0) {
				/* If actor already selected by group -> remove from group */
				setUpdatingSelection({optionType: 'actors', optionId: actorId});
				currentActorChoices.splice(actorIndex, 1);

				/* Update group (no need to check other groups */
				updateGroup({actors: currentActorChoices}).then(() => {
					setUpdatingSelection(null);
				});

			} else {
				/* Actor not already selected -> show "select role for actor" popup */
				setPopupActorId(actorId);
			}
		}
	};

	/**
	 * Toggle selected director / scenographer
	 * Checks whether the option is available, if yes then handles choice of cast
	 * @param {bool} isTaken 
	 * @param {bool} isSelectedByGroup 
	 * @param {string} optionType 
	 * @param {number} optionId 
	 */
	const handleCastChoice = (isTaken, optionType, optionId) => {
		if (!isTaken) {
			setUpdatingSelection({optionType, optionId});
			if (group[optionType] === optionId) {
				/* Deselect option */
				updateGroup({[optionType]: null}).then(() => {
					setUpdatingSelection(null);
				});
			} else {
				/* Select option */
				updateSpecialGroupProperty(optionType, optionId).then(() => {
					setUpdatingSelection(null);
				});
			}
		}
	};

	return (
		<>
			<div className="Casting">
				{/* Title */}
				<div className="Casting-title"><span>{gameStepData.groupInfo}</span></div>

				{/* Selected cast */}
				<div className="Casting-selectedCast">
					<div className="Casting-selectedInstructorAndScenographer">
						<div className="Casting-selectedPerson">
							<span>1 {gameUiTexts.instructor}:</span>
							<span>{(selectedInstructor ? selectedInstructor.name : '-')}</span>
						</div>
						<div className="Casting-selectedPerson">
							<span>1 {gameUiTexts.scenographer}:</span>
							<span>{(selectedScenographer ? selectedScenographer.name : '-')}</span>
						</div>
					</div>
					<div className="Casting-selectedActors">
						{selectedShowData && selectedShowData.roles.map((role) => {
							const selectedActorForRole = ((group.actors && group.actors.length > 0)
								? group.actors.find((ga) => {
									return ga.roleId === role.id;
								})
								: null	
							);
							
							const selectedActorData = (selectedActorForRole 
								? actors.find((a) => {return a.id === selectedActorForRole.actorId;})
								: null
							);
							
							return (
								<div key={role.id} className="Casting-selectedPerson">
									<span>{role.title}:</span>
									<span>{(selectedActorData ? selectedActorData.name : '-')}</span>
								</div>
							);
						})}
					</div>

				</div>

				<div className='Casting-lists'>
					{/* Instructor */}
					<div className='Casting-listWrapper instructor'>
						<div className='Casting-listTitle'>
							<span>{gameUiTexts.instructor}</span>
						</div>
						<div className='Casting-listContent'>
							<div className='Casting-personList'>
								{instructors.map((instructor, index) => {
									const isLast = index === instructors.length - 1;
									const isSelectedByGroup = (group.instructorId === instructor.id);
									const isTaken = !isSelectedByGroup && 
									checkIfOptionIsSelected('instructorId', instructor.id);

									return (
										<div key={index} className={'Casting-personWrap' + (isLast ? ' last' : '')}>
											<div className={'Casting-person instructor-' + instructor.id 
											+ (isTaken ? ' taken' : '')
											+ (isSelectedByGroup ? ' selected' : '')
											+ (
												updatingSelection && 
												updatingSelection.optionType === 'instructorId' &&
												updatingSelection.optionId === instructor.id
													? ' loading' : ''
											)}
											
											onClick={() => {
												handleCastChoice(isTaken, 'instructorId', instructor.id);
											}}>
												<span>{instructor.name}</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				
					{/* Scenographer */}
					<div className='Casting-listWrapper scenographer'>
						<div className='Casting-listTitle'>
							<span>{gameUiTexts.scenographer}</span>
						</div>
						<div className='Casting-listContent'>
							<div className='Casting-personList'>
								{scenographers.map((scenographer, index) => {
									const isLast = index === scenographers.length - 1;
									const isSelectedByGroup = (group.scenographerId === scenographer.id);
									const isTaken = !isSelectedByGroup && 
									checkIfOptionIsSelected('scenographerId', scenographer.id);

									return (
										<div key={index} className={'Casting-personWrap' + (isLast ? ' last' : '')}>
											<div 
												className={'Casting-person scenographer-' + scenographer.id 
												+ (isTaken ? ' taken' : '')
												+ (isSelectedByGroup ? ' selected' : '')
												+ (
													updatingSelection && 
													updatingSelection.optionType === 'scenographerId' &&
													updatingSelection.optionId === scenographer.id
														? ' loading' : ''
												)}
												onClick={() => {
													handleCastChoice(isTaken, 'scenographerId', scenographer.id);
												}}
											>
												<span>{scenographer.name}</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				
					{/* Actors */}
					<div className='Casting-listWrapper actors'>
						<div className='Casting-listTitle'>
							<span>{gameUiTexts.actors}</span>
						</div>
						<div className='Casting-listContent'>
							<div className='Casting-personList'>
								{actors.map((actor, index) => {
									const isLast = (index === actors.length - 1);
									const isSelectedByGroup = (
										group.actors && 
										group.actors.some((a) => {return a.actorId === actor.id;})
									);
									const isTaken = !isSelectedByGroup && 
										checkIfOptionIsSelectedArray('actors', 'actorId', actor.id);

									return (
										<div key={index} className={'Casting-personWrap' + (isLast ? ' last' : '')}>
											<div className={'Casting-person actor-' + actor.id 
											+ (isTaken ? ' taken' : '')
											+ (isSelectedByGroup ? ' selected' : '')
											+ (
												updatingSelection && 
												updatingSelection.optionType === 'actors' &&
												updatingSelection.optionId === actor.id
													? ' loading' : ''
											)}
											onClick={() => {
												handleSelectActorAndRole(isTaken, actor.id);
											}}>
												<span>{actor.name}</span>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			{popupActorId && <CastingPopup 
				actorId={popupActorId}
				selectedShowData={selectedShowData}
				updateSpecialGroupProperty={updateSpecialGroupProperty}
				setPopupActorId={setPopupActorId}
			/>}
		</>
	);
};

Casting.propTypes = {
	updateGroup: PropTypes.func.isRequired,
	updateSpecialGroupProperty: PropTypes.func.isRequired,
	gameStepId: PropTypes.string.isRequired,
	groups: PropTypes.array.isRequired,
	group: PropTypes.object.isRequired
};

export default Casting;