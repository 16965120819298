import React from 'react';
import PropTypes from 'prop-types';
import {facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-downloads.scss';

const PopupDownloads = ({setShowDownloadsPopup}) => {

	return (
		<div className="PopupDownloads">
			<div className="PopupDownloads-content">
				<div className='PopupDownloads-closeButton' onClick={() => {setShowDownloadsPopup(false);}}/>
				<div className='PopupDownloads-title'>
					<span>{facilitatorUiTexts.downloadsPopup.title}</span>
				</div>
				<div className='PopupDownloads-buttons'>
					<Button 
						classes={['yellowGradient', 'download']} 
						type="download" 
						href="/files/sæt-scenen-vejledning.pdf" 
						text={facilitatorUiTexts.downloadsPopup.manual}
					/>
					<Button 
						classes={['yellowGradient', 'download']} 
						type="download" 
						href="/files/sæt-scenen-bordskilte.pdf" 
						text={facilitatorUiTexts.downloadsPopup.tableCards}
					/>
					<Button 
						classes={['yellowGradient', 'download']} 
						type="download" 
						href="/files/sæt-scenen-aktører.pdf" 
						text={facilitatorUiTexts.downloadsPopup.cast}
					/>
				</div>
			</div>	
		</div>
	);
};

PopupDownloads.propTypes = {
	setShowDownloadsPopup: PropTypes.func.isRequired
};

export default PopupDownloads;
