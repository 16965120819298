import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {renderMarkdown} from 'helpers/text-helper';
import {loginUiTexts, facilitatorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './popup-settings.scss';

const PopupSettings = ({restartGame, handleLogout, toggleSettingsPopup}) => {

	const [isRestartingGame, setIsRestartingGame] = useState(false);

	const handleRestartGame = () => {
		if (isRestartingGame) return;

		setIsRestartingGame(true);
		restartGame().then((response) => {
			setIsRestartingGame(false);
			toggleSettingsPopup();
		});

	};
	return (
		<div className="PopupSettings">
			<div className="PopupSettings-content">
				<div className='PopupSettings-closeButton' onClick={() => {toggleSettingsPopup();}}/>
				<div className='PopupSettings-title'>
					<span>{facilitatorUiTexts.settingsPopup.title}</span>
				</div>
				<div className='PopupSettings-buttons'>
					<div className='PopupSettings-restartButton'>
						<Button 
							isLoading={isRestartingGame}
							classes={['yellowGradient', 'wide', 'bold']}
							text={facilitatorUiTexts.settingsPopup.restartGameBtn} 
							onClick={() => {handleRestartGame();}} 
						/>
					</div>
					<div className='PopupSettings-logoutButton'>
						<Button 
							classes={['yellowGradient', 'wide', 'bold']}
							text={loginUiTexts.logout}
							onClick={() => {handleLogout();}}
						/>
					</div>
				</div>
			</div>	
		</div>
	);
};

PopupSettings.propTypes = {
	restartGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	toggleSettingsPopup: PropTypes.func.isRequired
};

export default PopupSettings;
