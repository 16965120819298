/**
* actor img for review print
* @param {string} number 
*/
const getActorFileName = function(number) {
	let fileName = null;
	switch (number) {
	case 1:
		fileName = 'chris_braet.svg';
		break;
	case 2:
		fileName = 'jennifer_lovloes.svg';
		break;
	case 3:
		fileName = 'soeren_tal.svg';
		break;
	case 4:
		fileName = 'sofie_hejdrup.svg';
		break;
	case 5:
		fileName = 'bianca_madsen.svg';
		break;
	case 6:
		fileName = 'lene_norland.svg';
		break;
	case 7:
		fileName = 'erasmus_holberg.svg';
		break;
	case 8:
		fileName = 'rasmus_havebro.svg';
		break;
	case 9:
		fileName = 'john_delman.svg';
		break;
	case 10:
		fileName = 'thor_moelner.svg';
		break;
	case 11:
		fileName = 'luca_bakir.svg';
		break;
	case 12:
		fileName = 'knud_k.svg';
		break;
	case 13:
		fileName = 'michael_nguyen.svg';
		break;
	case 14:
		fileName = 'jamilla_ghazali.svg';
		break;
	case 15:
		fileName = 'mohammed_badawi.svg';
		break;
	case 16:
		fileName = 'elsa_sigurdsdottir.svg';
		break;
	case 17:
		fileName = 'andrea_frydenlund.svg';
		break;
	case 18:
		fileName = 'nikita_morgenstjerne.svg';
		break;
	case 19:
		fileName = 'freddy_langhorn.svg';
		break;
	case 20:
		fileName = 'kit_rex.svg';
		break;
	// Default
	default:
		fileName = '';
	}
	
	return fileName;
};

export { getActorFileName };