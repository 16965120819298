import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {loginUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import Game from 'components/game/game';

class GroupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			group: null,
			game: null,
			groups: []
		};
		this.timeout = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroup = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to group and game */
		Promise.all([
			this.subscribeToGame(), 
			this.subscribeToGroup(),
			this.subscribeToGroups()
		]).then((responses) => {
			if (responses[0].status === 'ok' && responses[1].status === 'ok') {
				this.setState({isLoading: false});

				if (!this.state.group.isPlaying) {
					this.updateGroup({isPlaying: true});
				}
			} else {
				console.error(responses);
			}
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Unsubscribe from game / group */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	}	;

	/**
	 * Subscribe to own group
	 * @param {string} groupId
	 */
	subscribeToGroup = () => {
		if (this.unsubscribeGroup !== null) this.unsubscribeGroup();

		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGroup = db.collection(appConfig.groupsDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					const group = {id: doc.id, ...doc.data()};
					// let prevGroupData = this.state.group;
					this.setState({group: group}, () => {
						// this.checkGroupUpdates(prevGroupData);
						resolve({ status: 'ok' });
					});
				} else {
					this.props.handleLogout();	
				}
			},
			(error) => {
				console.error('could not get group: ', error);
				this.props.handleLogout();
			}
			);
		});
	};

	/**
	 * Subscribe to game
	 */
	subscribeToGame = () => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		const gameId = this.props.userId.substr(0, this.props.userId.indexOf('-'));
		const db = firebase.firestore();
		return new Promise((resolve) => {
			this.unsubscribeGame = db.collection(appConfig.gamesDbName).doc(gameId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get updated game */
					let data = doc.data();
					data.id = doc.id;
					let prevGameData = this.state.gameData;
					this.setState({ game: data }, () => {
						this.checkGameUpdates(prevGameData);
						resolve({ status: 'ok' });
					});
				} else {
					/* No game, probably deleted: log out group */
					this.props.handleLogout();
				}
			},
			(error) => {
				/* Error: game not found */
				console.error('could not get game: ', error);
				resolve({ status: 'error', error: error });
			}
			);
		});
	};

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId
	 */
	 subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		const gameId = this.props.userId.substr(0, this.props.userId.indexOf('-'));
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve();// resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Check game updates
	 * @param {object} prevGameData
	 */
	checkGameUpdates = (prevGameData) => {
		/* Facilitator opened phase 2 */
		// if (prevGameData && this.state.gameData && prevGameData.phaseId !== this.state.gameData.phaseId) {
		// 	let roundData = roundsData.find((round) => {return round.id === this.state.groupData.roundId;});
		// 	if (roundData && roundData.showIntroPopup === true && this.state.gameData.phaseId === roundData.phaseId) {
		// 		this.setState({showIntroPopup: true});
		// 	}
		// }
	};

	/**
	 * Update group
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGroup = (updates) => {
		const groupId = this.props.userId;
		const db = firebase.firestore();
		const groupRef = db.collection(appConfig.groupsDbName).doc(groupId);
		return groupRef.update(updates);
	};

	/**
	 * Update selected feedback ids for presentation
	 * @param {string} actId 
	 * @param {string} groupId
	 * @param {number} feedbackId 
	 */
	updateSelectedFeedbackIds = (actId, groupId, feedbackId) => {
		/* Get data for presentation */
		let selectedFeedbackIds = (this.state.group.selectedFeedbackIds
			? JSON.parse(JSON.stringify(this.state.group.selectedFeedbackIds))
			: {}
		);
		if (!selectedFeedbackIds.hasOwnProperty(actId)) {
			selectedFeedbackIds[actId] = {};
		}

		/* Update data for act */
		if (!selectedFeedbackIds[actId].hasOwnProperty(groupId)) {
			/* First feedback id selected */
			selectedFeedbackIds[actId][groupId] = [feedbackId];
		} else {
			const feedbackIndex = selectedFeedbackIds[actId][groupId].indexOf(feedbackId);
			if (feedbackIndex >= 0) {
				/* Deselect feedback id */
				selectedFeedbackIds[actId][groupId].splice(feedbackIndex, 1);
			} else {
				if (selectedFeedbackIds[actId][groupId].length < appConfig.numberOfFeedbackOptionsToSelect) {
					/* Select feedback id */
					selectedFeedbackIds[actId][groupId].push(feedbackId);
				}
			}
		}
		
		/* Update database */
		this.updateGroup({selectedFeedbackIds});
	};

	/**
	 * Updates state for group controller with given object
	 * @param {Object} updates 
	 */
	updateGroupState = (updates) => {
		this.setState({...updates});
	};

	/**
	 * Checks state to see if it contains all variables in the given array, and whether they are set.
	 * @param {array} properties
	 * @return true if all properties exist and are set
	 */
	 checkStateProperties = (properties) => {
		let allSet = true;

		properties.forEach((property) => {
			if (!this.state.hasOwnProperty(property) || !this.state[property]) {
				allSet = false;
				return;
			}
		});

		return allSet;
	};

	/**
	 * Updates a group property if no other groups have chosen the same value for that property
	 * @param {string} property 
	 * @param {string} value 
	 * @returns 
	 */
	updateSpecialGroupProperty = (property, value, value2 = null) => {
		return new Promise((resolve) => {
			apiHelper('group/update-special-property', {
				gameId: this.state.game.id,
				groupId: this.props.userId,
				property: property,
				value: value,
				value2: value2
			}).then((response) => {
				resolve(response);
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={loginUiTexts.loadingGame}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		/* Game */
		return (
			<Game 
				isFacilitator={false}
				game={this.state.game}
				group={this.state.group}
				groups={this.state.groups}
				updateSelectedFeedbackIds={this.updateSelectedFeedbackIds}
				handleLogout={this.props.handleLogout}
				updateGroup={this.updateGroup}
				updateGroupState={this.updateGroupState}
				checkStateProperties={this.checkStateProperties}
				updateSpecialGroupProperty={this.updateSpecialGroupProperty}
			/>
		);
	};
}

GroupController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GroupController;
