import React from 'react';
import PropTypes from 'prop-types';
import {gameStepsData} from 'data/game-steps-data';
import {renderMarkdown} from 'helpers/text-helper';
import './assignment.scss';

const Assignment = ({gameStepId}) => {
	/* Game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	return (
		<div className={'Assignment ' + gameStepData.actId}>
			<div className="Assignment-box">
				<div className="Assignment-title"><span>{gameStepData.assignmentTitle}</span></div>
				<div className="Assignment-subtitle"><span>{gameStepData.assignmentSubtitle}</span></div>
				<div className="Assignment-text">{renderMarkdown(gameStepData.assignmentText)}</div>
			</div>
		</div>
	);
};

Assignment.propTypes = {
	gameStepId: PropTypes.string.isRequired
};

export default Assignment;