import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts, loginUiTexts} from 'data/ui-texts';
import {gameStepsData} from 'data/game-steps-data';
import Curtains from 'components/ui/curtains/curtains';
import GameCode from 'components/ui/game-code/game-code';
import Menu from 'components/ui/menu/menu';
import GroupBadge from 'components/ui/group-badge/group-badge';
import Instructions from 'components/ui/instructions/instructions';
import NextStepButton from 'components/ui/next-step-button/next-step-button';
import PrevStepButton from 'components/ui/prev-step-button/prev-step-button';
import Button from 'components/ui/button/button';
import Popup from 'components/ui/popup/popup';
import PopupDownloads from 'components/ui/popup-downloads/popup-downloads';
import './game.scss';

const Game = (props) => {
	/* Props */
	const {
		isFacilitator,
		game,
		group,
		groups,
		updateGroup,
		goToGameStep,
		updateGroupState,
		finishPresentingGroup,
		selectGroupForPresentation,
		handleLogout,
		findWinningGroup,
		updatePresentationFeedbackStatus,
		updateSelectedFeedbackIds,
		updateSpecialGroupProperty,
		toggleSettingsPopup,
		updateGame
	} = props;
	
	/* Current game step */
	const [gameStepId, setGameStepId] = useState((game.gameStepId ? game.gameStepId : gameStepsData[0].id));
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});

	/* Content component */
	const ContentComponent = (isFacilitator
		? gameStepData.facilitatorComponent ? gameStepData.facilitatorComponent : null
		: gameStepData.groupComponent ? gameStepData.groupComponent : null
	);

	/* Popup data */
	const [popupData, setPopupData] = useState(null);
	const [showDownloadsPopup, setShowDownloadsPopup] = useState(false);

	/* Curtain status (open, closed, wide-open) */
	const [curtainStatus, setCurtainStatus] = useState('closed');

	/**
	 * Update local state of "game step id" when game step is updated,
	 * Update local state of "winning group id" if updated
	 */
	 useEffect(() => {
		let timeout = null;
		
		if (game.gameStepId && game.gameStepId !== gameStepId) {
			setPopupData(null); 
			/* Close / open curtains if switching between acts */
			const currentGameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});
			const nextGameStepData = gameStepsData.find((gs) => {return gs.id === game.gameStepId;});
			if (game.gameStepId === 'reviews') {
				if (!isFacilitator) {
					if (!game.showReviews) {
						setCurtainStatus('closed');
					} else {
						setCurtainStatus('open');
					}
				} else {
					if (!game.winningGroupId) {
						setCurtainStatus('closed');
					} else {
						setCurtainStatus('open');
					}
				}
				
				setGameStepId(game.gameStepId);
			} else {
				if (currentGameStepData.actId !== nextGameStepData.actId) {
					setCurtainStatus('closed');
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						setGameStepId(game.gameStepId);
						setCurtainStatus('open');
					}, 1000);
				} else {
					setCurtainStatus('open');
					setGameStepId(game.gameStepId);
				}
			}
		}

		if (!isFacilitator && game && game.showReviews && curtainStatus === 'closed') {
			setCurtainStatus('open');
		}


		return () => {
			if (timeout) clearTimeout(timeout);
		};
	 // eslint-disable-next-line react-hooks/exhaustive-deps
	 }, [game]);


	/**
	 * Component did mount / will unmount 
	 */
	useEffect(() => {
		let timeout = null;
		/* Component did mount */
		if (gameStepId !== 'reviews' || (isFacilitator && game.winningGroupId) || (game.showReviews)) {
			timeout = setTimeout(function() {
				setCurtainStatus('open');
			}, 500);
		}
		return () => {
			clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* Show game code, menu, instructions */
	const showGameCodeAndMenu = (isFacilitator && curtainStatus !== 'open-wide');

	/* Show group badge in sidebar */
	const showGroupBadgeInSidebar = (!isFacilitator && curtainStatus !== 'open-wide');

	return (
		<div className="Game">
			{/* Main content */}
			<div className="Game-content">
				{ContentComponent && <ContentComponent 
					isFacilitator={isFacilitator}
					gameStepId={gameStepId}
					game={game}
					groups={groups}
					group={group}
					updateGroup={updateGroup}
					updateGroupState={updateGroupState}
					setPopupData={setPopupData}
					setCurtainStatus={setCurtainStatus}
					selectGroupForPresentation={selectGroupForPresentation}
					updateSelectedFeedbackIds={updateSelectedFeedbackIds}
					updateSpecialGroupProperty={updateSpecialGroupProperty}
					findWinningGroup={findWinningGroup}
				/>}
			</div>

			{/* Curtains */}
			<Curtains status={curtainStatus} />

			{/* Audience */}
			{gameStepData.showAudience && <div className="Game-audience" />}

			{/* Left sidebar */}
			<div className={'Game-sidebar left ' + curtainStatus}>
				{/* Game code (facilitator only) */}
				{showGameCodeAndMenu && <GameCode code={game.id} />}
				{/* Menu (facilitator only) */}
				{showGameCodeAndMenu && <Menu gameStepId={gameStepId} goToGameStep={goToGameStep} />}
				{/* Prev game step btn (facilitator only)*/}
				{isFacilitator && <PrevStepButton gameStepId={gameStepId} goToGameStep={goToGameStep} />}
				{/* Group badge (group only) */}
				{showGroupBadgeInSidebar && <GroupBadge type="sidebar" group={group}/>}
				{!isFacilitator && <div className="Game-logoutBtn">
					<Button 
						classes={['logout']}
						text={loginUiTexts.logout}
						onClick={() => {handleLogout();}}
					/>
				</div>
				}
			</div>

			{/* Right sidebar */}
			<div className={'Game-sidebar right ' + curtainStatus}>
				{isFacilitator && <div className="Game-settingsBtn">
					<Button classes={['settings']} onClick={() => {toggleSettingsPopup();}} />
				</div>}
				{isFacilitator &&
					<Instructions 
						isFacilitator={true} 
						curtainStatus={curtainStatus}
						gameStepId={gameStepId} 
						game={game} 
						groups={groups}
					/>
				}
				{(isFacilitator && gameStepData.showDownloadMaterialsBtn === true) &&
					<Button 
						classes={['materials']} 
						text={gameUiTexts.downloadMaterials}
						onClick={() => {setShowDownloadsPopup(true);}}
					/>
				}
				{(!isFacilitator && gameStepData.showDownloadExample === true) &&
					<Button 
						classes={['materials', 'small']} 
						type="download" 
						href="/files/manuskript-eksempel.docx" 
						text={gameUiTexts.downloadManusExample}
					/>
				}
				{isFacilitator && 
					<NextStepButton 
						gameStepId={gameStepId} 
						game={game}
						groups={groups}
						goToGameStep={goToGameStep} 
						updatePresentationFeedbackStatus={updatePresentationFeedbackStatus}
						finishPresentingGroup={finishPresentingGroup}
						setPopupData={setPopupData}
						updateGame={updateGame}
					/>	
				}
			</div>

			{/* Popup */}
			{popupData && <Popup
				hasCloseButton={true}
				closePopup={() => {setPopupData(null);}}
				title={popupData.title}
				text={popupData.text}
				type={popupData.type ? popupData.type : null}
				buttons={popupData.buttons}
			/>}

			{/* Downloads popup */}
			{showDownloadsPopup && <PopupDownloads setShowDownloadsPopup={setShowDownloadsPopup} />}


		</div>
	);
};

Game.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	groups: PropTypes.array,
	goToGameStep: PropTypes.func,
	selectGroupForPresentation: PropTypes.func,
	updatePresentationFeedbackStatus: PropTypes.func,
	finishPresentingGroup: PropTypes.func,
	updateSelectedFeedbackIds: PropTypes.func,
	toggleSettingsPopup: PropTypes.func,
	handleLogout: PropTypes.func.isRequired,
	findWinningGroup: PropTypes.func,
	updateGroup: PropTypes.func,
	updateSpecialGroupProperty: PropTypes.func,
	updateGroupState: PropTypes.func,
	updateGame: PropTypes.func,
};

export default Game;