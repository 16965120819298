/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyC4gers3TetppkUd4hXuxSOo-lc_yRMKSA',
		authDomain: 'cgl-teaterspillet-test.firebaseapp.com',
		projectId: 'cgl-teaterspillet-test',
		storageBucket: 'cgl-teaterspillet-test.appspot.com',
		messagingSenderId: '770329094492',
		appId: '1:770329094492:web:6bf58bd7103cd614ddb999'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyBBY8f2gNpD2JIAx3FxDL8-mrcElsYdrFE',
		authDomain: 'cgl-teaterspillet-production.firebaseapp.com',
		projectId: 'cgl-teaterspillet-production',
		storageBucket: 'cgl-teaterspillet-production.appspot.com',
		messagingSenderId: '248387374202',
		appId: '1:248387374202:web:46904d40960bbfcb3bbfc3'
	};
}


/* Initialize firebase */
// const app = firebase.initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);
/* Initialize analytics */
// if (env === 'production') {
// 	firebase.analytics(app);
// }

export default firebase.firestore;