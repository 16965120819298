import React from 'react';
import {imagesData} from 'data/images-data';
import './image-loader.scss';

const ImageLoader = () => {
	return (
		<div className="ImageLoader">
			{imagesData.map((file, index) => {
				const imgSrc = require('assets/images/' + file);
				return <img key={index} src={imgSrc} alt="preload" />;
			})}
		</div>
	);
};



export default ImageLoader;