import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {gameUiTexts} from 'data/ui-texts';
import {reviewsData} from 'data/reviews-data';
import {renderMarkdown} from 'helpers/text-helper';
import { getActorFileName } from 'helpers/get-actor-image';
import {getNames, getMainPartText, replaceDynamicText} from 'helpers/reviews-helper';
// NOTE: style sheet is in public folder

const ReviewPrint = ({isLastGroup, group}) => {

	/* Get images */
	const culturePath = 'review-print/title-culture.svg';
	const theatrePath = 'review-print/title-theatre.svg';
	const starPath = '/review-print/icon-star.svg';
	const starFilledPath = '/review-print/icon-star-filled.svg';

	/* Get names of group, show, genre and cast */
	const names = getNames(group);

	/* Get title, blurb and ending texts */
	let title = '';
	let blurb = '';
	let ending = '';

	let specificTitleBlurbEndingOptions = reviewsData.specificTitleBlurbEnding.find((tb) => {
		return (tb.numberOfStars.indexOf(group.stars) >= 0);
	});
	if (specificTitleBlurbEndingOptions) {
		if (specificTitleBlurbEndingOptions.texts.length > group.reviewIndex) {
			title = specificTitleBlurbEndingOptions.texts[group.reviewIndex].title;
			blurb = specificTitleBlurbEndingOptions.texts[group.reviewIndex].blurb;	
			ending = specificTitleBlurbEndingOptions.texts[group.reviewIndex].ending;	
		} else {
			const modifiedGroupReviewIndex = group.reviewIndex - specificTitleBlurbEndingOptions.texts.length;
			if (reviewsData.randomTitleBlurbEnding.length > modifiedGroupReviewIndex) {
				title = reviewsData.randomTitleBlurbEnding[modifiedGroupReviewIndex].title;
				blurb = reviewsData.randomTitleBlurbEnding[modifiedGroupReviewIndex].blurb;
				ending = reviewsData.randomTitleBlurbEnding[modifiedGroupReviewIndex].ending;
			}
		}
	}

	/* Get info title */
	const infoTitle = names.groupName + ', ' + (names.groupName.length + names.showName.length > 30 
		? '<br />' 
		: '')  
		+ names.showName;
	
	/* Get main pars */
	let mainPart1 = getMainPartText(1, group);
	let mainPart2 = getMainPartText(2, group);
	let mainPart3 = getMainPartText(3, group);
	
	
	/* Replace dynamic text parts */
	if (title) title = replaceDynamicText(title, names, group.stars);
	if (blurb) blurb = replaceDynamicText(blurb, names, group.stars);
	if (ending) ending = replaceDynamicText(ending, names, group.stars);
	if (mainPart1) mainPart1 = replaceDynamicText(mainPart1, names, group.stars);
	if (mainPart2) mainPart2 = replaceDynamicText(mainPart2, names, group.stars);
	if (mainPart3) mainPart3 = replaceDynamicText(mainPart3, names, group.stars);
	
	/* Layout configs */
	let titleClass = 'ReviewPrint-title';
	if (title.length > 30 && title.length < 42) titleClass += ' small';
	if (title.length >= 42) titleClass += ' tiny';
	/* images of the actors */
	let actor1ImgPath = null;
	let actor2ImgPath = null;
	let actor3ImgPath = null;

	if (group && group.actors && group.actors.length > 0) {
		actor1ImgPath = '/review-print/cast/actors/' + getActorFileName(group.actors[0].actorId);

		if (group.actors.length > 1) {
			actor2ImgPath = '/review-print/cast/actors/' + getActorFileName(group.actors[1].actorId);

			if (group.actors.length > 2) {
				actor3ImgPath = '/review-print/cast/actors/' + getActorFileName(group.actors[2].actorId);
			}
		}
	}

	return (
		<div className={'ReviewPrint' + (isLastGroup ? ' last' : '')}>
			<div 
				className="ReviewPrint-content"
				style={{backgroundColor: '#FCFAF0'}}
			>
				<div className="ReviewPrint-theme"><img src={culturePath} alt={gameUiTexts.culture} /> </div>
				
				{/* Cast */}
				<div className="ReviewPrint-people">
					<div className={'ReviewPrint-person'}> 
						{actor1ImgPath && <img src={actor1ImgPath} alt={names.actor1Name} />}
					</div>
					<div className={'ReviewPrint-person'}> 
						{actor2ImgPath && <img src={actor2ImgPath} alt={names.actor2Name} />}
					</div>
					<div className={'ReviewPrint-person'}> 
						{actor3ImgPath && <img src={actor3ImgPath} alt={names.actor3Name} />}
					</div>

				</div>
				{/* Title */}
				<div className={titleClass}><span>{title}</span></div>
				<div className="ReviewPrint-text">
					<div className="ReviewPrint-textIntro">
						{/* Blurb */}
						<div className="ReviewPrint-blurb">{renderMarkdown(blurb)}</div>

						{/* Subtitle */}
						<div className="ReviewPrint-subtitle">
							<img src={theatrePath} alt={gameUiTexts.theatre} />
						</div>

						{/* Info */}
						<div className="ReviewPrint-info">
							<div className="ReviewPrint-infoTitle">{renderMarkdown(infoTitle)}</div>
							<div className="ReviewPrint-infoText">{renderMarkdown(gameUiTexts.review.info)}</div>
						</div>

						{/* Stars */}
						<div className="ReviewPrint-stars">
							{[ ...Array(appConfig.maxStars).keys()].map((i) => {
								const isFilled = (group.stars > i);
								return (
									<div key={i} className={'ReviewPrint-star'}>
										<img src={(isFilled ? starFilledPath : starPath)} alt={gameUiTexts.star} /> 
									</div>
								);
							})}
						</div>
					</div>

					{/* Main review parts */}
					<div className="ReviewPrint-reviewText">
						{mainPart1 && renderMarkdown(mainPart1)}
						{mainPart2 && renderMarkdown(mainPart2)}
						{mainPart3 && renderMarkdown(mainPart3)}
						{renderMarkdown(ending)}
					</div>

				</div>
			</div>

		</div>
	);

};

ReviewPrint.propTypes = {
	isLastGroup: PropTypes.bool.isRequired,
	group: PropTypes.object.isRequired
};

export default ReviewPrint;