import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getGroupStars} from 'helpers/reviews-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import Review from 'components/ui/review/review';
import GroupBadge from 'components/ui/group-badge/group-badge';
import './finish.scss';

const Finish = ({isFacilitator, game, group, groups, findWinningGroup, setCurtainStatus}) => {
	/* Find winning group button status */
	const [isLoading, setIsLoading] = useState(false);

	/* Selected group index */
	const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

	/**
	 * Find winning group
	 */
	const handleFindWinningGroup = () => {
		setIsLoading(true);
		findWinningGroup().then(() => {
			setIsLoading(false);
			setCurtainStatus('open');
		});
	};

	/* Winner has not been found yet */
	if (!game.winningGroupId) {
		if (!isFacilitator) return null;

		return (
			<div className="Finish">
				<div className={'Finish-showWinnerBtn' + (isLoading ? ' loading' : '')}>
					<Button 
						isLoading={isLoading}
						classes={['showWinner']}
						onClick={() => {handleFindWinningGroup();}}
					/>
				</div>
			</div>
		);
	}

	/* Winner has been found, get group stars */
	const activeGroups = groups.filter((g) => {return g.isPlaying === true;});
	let groupStars = getGroupStars(game, activeGroups);

	/* Winner is being presented */
	if (game.showReviews !== true) {
		if (!isFacilitator) return null;
		const winningGroupData = groupStars.find((g) => {return g.id === game.winningGroupId;});
		return (
			<div className="Finish">
				{winningGroupData && <Review group={winningGroupData} />}
			</div>
		);
	}

	/* Show review(s) */ 
	if (!isFacilitator) {
		/* Show group review */
		const groupData = groupStars.find((g) => {return g.id === group.id;});
		return (
			<div className="Finish">
				{groupData && <Review group={groupData} />}
			</div>
		);
	}

	/* Show all group reviews */
	groupStars = sortArrayByProperty(groupStars, 'id', 'ASC');	
	const selectedGroup = groupStars[selectedGroupIndex];
	return (
		<div className="Finish">
			<div className="Finish-groups">
				{groupStars.map((group, index) => {
					return (
						<div 
							key={group.id} 
							className={'Finish-group' + (selectedGroupIndex === index ? ' selected' : '')}
							onClick={() => {setSelectedGroupIndex(index);}}
						>
							<GroupBadge type="reviews" group={group} />
						</div>
					);
				})}
			</div>
			<div className="Finish-selectedGroup">
				{selectedGroup && <Review group={selectedGroup} />}
			</div>
		</div>
	);
};

Finish.propTypes = {
	isFacilitator: PropTypes.bool.isRequired,
	game: PropTypes.object.isRequired,
	group: PropTypes.object,
	groups: PropTypes.array.isRequired,
	findWinningGroup: PropTypes.func,
	setCurtainStatus: PropTypes.func.isRequired
};

export default Finish;