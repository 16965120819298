import React from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import './game-code.scss';

const GameCode = ({code}) => {
	return (
		<div className="GameCode">
			<span>{gameUiTexts.gameCode}:</span><span>{code}</span>
		</div>
	);
};

GameCode.propTypes = {
	code: PropTypes.string.isRequired
};



export default GameCode;