import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import GroupBadge from 'components/ui/group-badge/group-badge';
import Loading from 'components/loading/loading';
import './login-group.scss';

const LoginGroup = (props) => {
	const {
		isLoading,
		code,
		groups,
		groupId,
		feedback,
		handleInput,
		handleFindGame,
		handleGetLoginToken
	} = props;

	/* Input game code */
	if (groups === null) {
		return (
			<div className="LoginGroup">
				<div className="LoginGroup-title"><span>{loginUiTexts.groupLogin}</span></div>
				<div className="LoginGroup-body">
					<div className='LoginGroup-loginDirection'><span>{loginUiTexts.useGameCode}</span></div>
					<form className="LoginGroup-form" onSubmit={(e) => {handleFindGame(e);}}>
						<input
							name="code"
							type="text"
							placeholder={loginUiTexts.gameCode} 
							autoComplete="section-grouplogin code"
							value={code ? code : ''}
							onChange={(event)=>{handleInput(event);}}
						/>
						<div className="LoginGroup-loginBtn">
							<Button 
								isLoading={isLoading}
								classes={['login']}
								text={loginUiTexts.login}
								onClick={() => {handleFindGame();}}
							/>
						</div>
						<div className="LoginGroup-errorMessage">{feedback}</div>
					</form>
				</div>
			</div>
		);
	}

	/* Logging in group (loading) */
	if (isLoading) {
		return <Loading loadErrMsg={loginUiTexts.loggingIn} />;
	}

	/* Select group */
	return (
		<div className="LoginGroup groups">
			<div className="LoginGroup-groups">
				{groups.map((group, index)=>{
					return (
						<div 
							key={index} 
							className={'LoginGroup-groupBtn' + 
								(isLoading && group.id === groupId ? ' loading' : '')}
							onClick={() => {handleGetLoginToken(group.id);}}
						>
							<GroupBadge type="login-choose-group" group={group} />
						</div>
					);
				})}
			</div>
		</div>
	);
		
};

LoginGroup.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	code: PropTypes.any,
	groups: PropTypes.array,
	groupId: PropTypes.string,
	feedback: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleFindGame: PropTypes.func.isRequired,
	handleGetLoginToken: PropTypes.func.isRequired
};

export default LoginGroup;