import React from 'react';
import PropTypes from 'prop-types';
import {groupsData} from 'data/groups-data';
import {genresData} from 'data/genres-data';
import {showsData} from 'data/shows-data';
import {gameUiTexts} from 'data/ui-texts';
import {checkIfOptionIsSelectedByAnyGroup, getGroupIdsOfGroupWithSelectedOption} from 'helpers/game-helper';
import './choose-show-facilitator.scss';

const ChooseShowFacilitator = ({groups}) => {
	return (
		<div className="ChooseShowFacilitator">
			<div className='ChooseShowFacilitator-choicesWrapper'>

				{/* Shows list of shows. Checks if any are already taken */}
				<div className='ChooseShowFacilitator-showsWrapper'>
					<div className='ChooseShowFacilitator-showTitle'>
						<span>{gameUiTexts.show}</span>
					</div>
					<div className='ChooseShowFacilitator-shows'>
						{showsData.map((show, index) => {
							const alreadyChosen = checkIfOptionIsSelectedByAnyGroup('showId', show.id, groups);
							const selectedByGroupIds = (alreadyChosen 
								? getGroupIdsOfGroupWithSelectedOption('showId', show.id, groups) 
								: []
							);
							return (
								<div 
									key={index} 
									className={'ChooseShowFacilitator-show' 
										+ (alreadyChosen ? ' active' : '')
										+ (selectedByGroupIds && selectedByGroupIds.length > 0 
											? ' ' + selectedByGroupIds[0] : '')
									}
								>
									<span></span>
									<span>{show.value}</span>
								</div>
							);
						})}
					</div>
				</div>
				
				{/* Shows list of genres. Checks if any are already taken */}
				<div className='ChooseShowFacilitator-genresWrapper'>
					<div className='ChooseShowFacilitator-genreTitle'>
						<span>{gameUiTexts.genre}</span>
					</div>
					<div className='ChooseShowFacilitator-genres'>
						{genresData.map((genre, index) => {
							const alreadyChosen = checkIfOptionIsSelectedByAnyGroup('genreId', genre.id, groups);
							const selectedByGroupIds = (alreadyChosen 
								? getGroupIdsOfGroupWithSelectedOption('genreId', genre.id, groups) 
								: []
							);
							let style = null;
							if (selectedByGroupIds && selectedByGroupIds.length > 0) {
								if (selectedByGroupIds.length === 1) {
									/* Only one group has selected genre, solid background color */
									const groupData = groupsData.find((g) => {return g.id === selectedByGroupIds[0];});
									style = {backgroundColor: groupData.color};
								} else {
									/* More than one group have selected genre, tilted graident background color */
									let gradient = 'linear-gradient(-70deg,';
									selectedByGroupIds.forEach((groupId, index) => {
										let groupWidth = Math.round(100. / selectedByGroupIds.length);
										const groupData = groupsData.find((g) => {return g.id === groupId;});
										if (index === 0) {
											gradient += ' ' + groupData.color + ' 0%, ' 
												+ groupData.color + ' ' + groupWidth + '%';
											return;
										}
										if (index + 1 === selectedByGroupIds.length) {
											gradient += ', ' + groupData.color + ' ' + (index * groupWidth) + '%, ' 
												+ groupData.color + ' 100%)';
											return;
										}
										gradient += ', ' + groupData.color + ' ' + (index * groupWidth) + '%, '
											+ groupData.color + ' ' + ((index + 1) * groupWidth) + '%';
									});
									style = {backgroundImage: gradient};
								}
							}
							return (
								<div 
									key={index} 
									className={'ChooseShowFacilitator-genre' + (alreadyChosen ? ' active' : '')}
								>
									<span style={style}></span>
									<span>{genre.value}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

ChooseShowFacilitator.propTypes = {
	groups: PropTypes.array.isRequired,
};

export default ChooseShowFacilitator;