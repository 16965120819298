import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {gameStepsData} from 'data/game-steps-data';
import {gameUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {getGroupStars} from 'helpers/reviews-helper';
import {handlePrintReviews} from 'helpers/print-helper';

import './instructions.scss';


const Instructions = ({curtainStatus, gameStepId, game, groups}) => {
	/* Get game step data */
	const gameStepData = gameStepsData.find((gs) => {return gs.id === gameStepId;});
	
	/* Get default instructions */
	let instructionsText = (gameStepData.facilitatorInstructions 
		? JSON.parse(JSON.stringify(gameStepData.facilitatorInstructions))
		: null
	);

	/* Special case: reviews */
	if (gameStepData.id === 'reviews') {
		if (!game.winnerId) {
			/* Winner not revealed yet */
			instructionsText = null;
		} else {
			if (game.showReviews === true) {
				instructionsText = JSON.parse(JSON.stringify(gameStepData.facilitatorInstructions2));
			}
		}
	}
	
	/* Special case: presentation */
	if (gameStepData.type === 'presentation') {
		/* Get active groups */
		const activeGroups = groups.filter((g) => {return g.isPlaying === true;});

		if (activeGroups.length === 0) {
			instructionsText += gameUiTexts.presentationWarning;
		} else {
			if (
				game && 
				game.presentations && 
				game.presentations.hasOwnProperty(gameStepData.actId)
			) {
				const activeGroups = groups.filter((g) => {return g.isPlaying === true;});
				const presentationData = game.presentations[gameStepData.actId];
				if (!presentationData.selectedGroupId) {
					/* No group selected (defalt is "select first group") */
					if (presentationData.finishedGroupIds.length > 0) {
						/* At least one group has presented */
						if (presentationData.finishedGroupIds.length < activeGroups.length) {
							/* Select next group to present */
							instructionsText = gameStepData.facilitatorInstructions2;
						} else {
							/* All groups have presented */
							instructionsText = null;
						}
					}
				} else {
					/* A group is presenting */
					if (!presentationData.feedbackStatus || presentationData.feedbackStatus === 'ready') {
						/* Continue to feedback */
						instructionsText = gameStepData.facilitatorInstructions3;
					} else {
						if (presentationData.feedbackStatus === 'open') {
							/* Selecte feedback options */
							instructionsText = gameStepData.facilitatorInstructions4;
						} else {
							/* View feedback */
							instructionsText = null;
						}
					}
				}
			}
		}
	}

	/* Special case: reviews */
	if (gameStepData.id === 'reviews') {
		if (!game.winningGroupId) {
			/* Winner not revealed yet */
			instructionsText = null;
		} else {
			if (game.showReviews === true) {
				instructionsText = <div>
					{renderMarkdown(gameStepData.facilitatorInstructions2)}
					{(game && groups.length > 0) && <>
						<p>
							{gameStepData.facilitatorInstructions3}
							<button onClick={() => {handlePrintReviews(groupStars);}}>
								{gameStepData.facilitatorInstructions4}
							</button>
							{gameStepData.facilitatorInstructions5}
						</p>
					</>}
					{renderMarkdown(gameStepData.facilitatorInstructions6)}
				</div>;
			} else {
				instructionsText = gameStepData.facilitatorInstructions;
			}
		}
	}
	


	/* No instructions */
	if (!instructionsText) return null;


	/* Replace dynamic parts */
	if (typeof instructionsText === 'string') {
		instructionsText = instructionsText.replace('%GAMEURL%', appConfig.gameUrl);
		instructionsText = instructionsText.replace('%GAMECODE%', game.id);
	}


	const activeGroups = groups.filter((g) => {return g.isPlaying === true;});
	const groupStars = getGroupStars(game, activeGroups);
	return (
		<div className={'Instructions ' + curtainStatus}>
			<div className="Instructions-title"><span>{gameUiTexts.toFacilitator}</span></div>
			<div className="Instructions-text">
				{(typeof instructionsText === 'string' ? renderMarkdown(instructionsText) : instructionsText)}
			</div>		
		</div>
	);
};

Instructions.propTypes = {
	curtainStatus: PropTypes.string.isRequired,
	gameStepId: PropTypes.string.isRequired,
	game: PropTypes.object.isRequired,
	groups: PropTypes.array,
};

export default Instructions;