import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {statsUiTexts, loginUiTexts} from 'data/ui-texts';
import {sortArrayByProperty} from 'helpers/array-helper';
import Button from 'components/ui/button/button';
import './stats.scss';

const Stats = ({users, games, groups, handleLogout}) => {
	/* Sort parameters */
	const [sortByProperty, setSortByProperty] = useState('created');
	const [sortDirection, setSortDirection] = useState('DESC');

	/* Change sort parameters */
	const handleSort = (property) => {
		if (property === sortByProperty) {
			const newSortDirection = (sortDirection === 'ASC' ? 'DESC' : 'ASC');
			setSortDirection(newSortDirection);
		} else {
			setSortDirection('DESC');
			setSortByProperty(property);
		}
	};

	/* Get number of started / finished games */
	// let numberOfArchivedGames = 0;
	let numberOfArchivedStartedRealGames = 0; // 2+ groups
	let numberOfArchivedFinishedTestGames = 0; // 0 or 1 groups
	let numberOfArchivedFinishedRealGames = 0; // 2+ groups
	users.forEach((user) => {
		if (user.games && user.games.length > 0) {
			// numberOfArchivedGames += user.games.length;
			user.games.forEach((g) => {
				if (g.winningGroupId) {
					if (g.hasOwnProperty('numberOfGroups') && g.numberOfGroups > 1) {
						numberOfArchivedStartedRealGames += 1;
						numberOfArchivedFinishedRealGames += 1;
					} else {
						numberOfArchivedFinishedTestGames += 1;
					}
				} else {
					if (g.hasOwnProperty('numberOfGroups') && g.numberOfGroups > 1) {
						numberOfArchivedStartedRealGames += 1;
					}
				}
			});
		}
	});
	let numberOfActiveStartedRealGames = 0;
	let numberOfActiveFinishedTestGames = 0;
	let numberOfActiveFinishedRealGames = 0;
	games.forEach((game) => {
		const numberOfActiveGroups = groups.filter((group) => {
			return (group.gameId === game.id && group.isPlaying === true);
		}).length;
		if (game.winningGroupId) {
			if (numberOfActiveGroups > 1) {
				numberOfActiveStartedRealGames += 1;
				numberOfActiveFinishedRealGames += 1;
			} else {
				numberOfActiveFinishedTestGames += 1;
			}
		} else {
			if (numberOfActiveGroups > 1) {
				numberOfActiveStartedRealGames += 1;
			}
		}
	}); 

	const numberOfUsers = users.length;
	const numberOfFinishedTestGames = numberOfArchivedFinishedTestGames + numberOfActiveFinishedTestGames;
	const numberOfStartedRealGames = numberOfArchivedStartedRealGames + numberOfActiveStartedRealGames;
	const numberOfFinishedRealGames = numberOfArchivedFinishedRealGames + numberOfActiveFinishedRealGames;

	
	/* Sort users */
	const sortedUsers = sortArrayByProperty(users, sortByProperty, sortDirection);

	return (
		<div className="Stats">
			<a href="/" className="Stats-backToGameBtn">{statsUiTexts.backToGame}</a>
			<div className="Stats-logoutBtn">
				<Button 
					classes={['logout']}
					text={loginUiTexts.logout}
					onClick={() => {handleLogout();}}
				/>
			</div>
			<div className="Stats-title"><span>{statsUiTexts.stats}</span></div>

			<div className="Stats-users">
				<span>{statsUiTexts.numberOfUsers}: </span>
				<span>{numberOfUsers}</span>
			</div>
			<div className="Stats-games">
				<span>{statsUiTexts.numberOfFinishedTestGames}: </span>
				<span>{numberOfFinishedTestGames}</span>
			</div>
			<div className="Stats-games">
				<span>{statsUiTexts.numberOfStartedGames}: </span>
				<span>{numberOfStartedRealGames}</span>
			</div>
			<div className="Stats-games">
				<span>{statsUiTexts.numberOfFinishedGames}: </span>
				<span>{numberOfFinishedRealGames}</span>
			</div>

			
			<div className="Stats-table">
				<div className="Stats-tableHeader">
					<div className="Stats-row">
						<div 
							className="Stats-cell email"
							onClick={() => {handleSort('email');}}
						>
							<span>{statsUiTexts.email}</span>
						</div>
						<div 
							className="Stats-cell created"
							onClick={() => {handleSort('created');}}
						>
							<span>{statsUiTexts.created}</span>
						</div>
						<div className="Stats-cell gamesFinished">
							<span>{statsUiTexts.finishedTestGames}</span></div>
						<div className="Stats-cell gamesStarted"><span>{statsUiTexts.startedGames}</span></div>
						<div className="Stats-cell gamesFinished">
							<span>{statsUiTexts.finishedGames}</span></div>						
					</div>
				</div>
				<div className="Stats-tableBody">
					{sortedUsers.map((user) => {
						const currentGame = games.find((g) => {return g.facilitatorId === user.id;});
						let startedRealGames = 0; // (currentGame ? 1 : 0) + (user.games ? user.games.length : 0);
						let finishedTestGames = 0;
						let finishedRealGames = 0;
						if (user.games) {
							user.games.forEach((g) => {
								if (g.winningGroupId) {
									if (g.hasOwnProperty('numberOfGroups') && g.numberOfGroups > 1) {
										startedRealGames += 1;
										finishedRealGames += 1;
									} else {
										finishedTestGames += 1;
									}
								} else {
									if (g.hasOwnProperty('numberOfGroups') && g.numberOfGroups > 1) {
										startedRealGames += 1;
									}
								}
							});
						}
						if (currentGame) {
							const numberOfActiveGroups = groups.filter((group) => {
								return (group.gameId === currentGame.id && group.isPlaying === true);
							}).length;
							if (currentGame.winningGroupId) {
								if (numberOfActiveGroups > 1) {
									startedRealGames += 1;
									finishedRealGames += 1;
								} else {
									finishedTestGames += 1;
								}
							} else {
								if (numberOfActiveGroups > 1) {
									startedRealGames += 1;
								}
							}
							
						}

						return (
							<div key={user.id} className="Stats-row">
								<div className="Stats-cell email"><span>{user.email}</span></div>
								<div className="Stats-cell created"><span>{user.created}</span></div>
								<div className="Stats-cell gamesFinished"><span>{finishedTestGames}</span></div>
								<div className="Stats-cell gamesStarted"><span>{startedRealGames}</span></div>
								<div className="Stats-cell gamesFinished"><span>{finishedRealGames}</span></div>
								
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

Stats.propTypes = {
	users: PropTypes.array.isRequired,
	games: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Stats;