import React from 'react';
import PropTypes from 'prop-types';
import './curtains.scss';

const Curtains = ({status}) => {
	return (
		<div className="Curtains">
			<div className={'Curtains-left' + (status ? ' ' + status : '')} />
			<div className={'Curtains-right' + (status ? ' ' + status : '')} />
		</div>
	);
};

Curtains.propTypes = {
	status: PropTypes.string
};

export default Curtains;