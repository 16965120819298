let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let usersDbName = 'users';
let gamesDbName = 'games';
let groupsDbName = 'groups';
let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-teaterspillet-test.web.app';
	apiURL = 'https://cgl-teaterspillet-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-teaterspillet-demo.web.app';
		apiURL = 'https://cgl-teaterspillet-demo.web.app/api/';
		gamesDbName = 'games-demo';
		groupsDbName = 'groups-demo';
	}
}
if (env === 'production') {
	gameUrl = 'sætscenen.dk';
	apiURL = 'https://cgl-teaterspillet-production.web.app/api/';
}

const appConfig = {
	env: env,
	apiURL: apiURL,
	gameUrl: gameUrl,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	groupsDbName: groupsDbName,
	numberOfFeedbackOptionsToSelect: 2,
	numberOfActorsToSelect: 3,
	maxStars: 6,
	cookiesAcceptedCookieName: 'teaterspillet_cookies_accepted'
};

export default appConfig;