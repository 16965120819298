import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import {findWinningGroupId} from 'helpers/reviews-helper';
import {loginUiTexts} from 'data/ui-texts';
import {groupsData} from 'data/groups-data';
import Loading from 'components/loading/loading';
import Game from 'components/game/game';
import PopupSettings from 'components/ui/popup-settings/popup-settings';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showSettingsPopup: false,
			userData: null,
			game: null,
			groups: []
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeGame = null;
		this.unsubscribeGroups = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get facilitator data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				/* Subscribe to game */
				this.subscribeToGame().then(() => {
					if (!this.state.game) {
						/* No game? -> create game */
						this.createGame().then(() => {
							this.setState({isLoading: false});
						}).catch((error) => {
							console.error(error);
						});
					} else {
						this.setState({isLoading: false});
					}
				});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe from game & groups */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		
	};

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.usersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {resolve({ status: 'success' });});
				} else {
					console.error('user data not found');
					this.setState({loadErrMsg: 'Error: User data not in database. Auto log out in 5 seconds.'}, () => {
						this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
					});
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				this.setState({loadErrMsg: 'Error: ' + error.code + '. Auto log out in 5 seconds.'}, () => {
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				});
			});
		});
	};

	/**
	 * Called by the facilitator to subscribe to their game
	 */
	subscribeToGame = () => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName).where('facilitatorId', '==', this.props.userId);			
			this.unsubscribeGame = query.onSnapshot((querySnapshot) => {
				let game = null;
				querySnapshot.forEach((doc) => {
					if (!game) game = {id: doc.id, ...doc.data()};
				});

				const startNewGroupSubscription = (game && (!this.state.game || this.state.game.id !== game.id) 
					? true : false);
				this.setState({game: game}, () => {
					if (startNewGroupSubscription) this.subscribeToGroups();
					resolve({status: 'success'});
				});
			}, (error) => {
				console.error('could not get game: ', error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Subscribe to the groups of a game
	 * @param {string} gameId
	 */
	subscribeToGroups = () => {
		if (this.unsubscribeGroups !== null) this.unsubscribeGroups();
		
		const gameId = this.state.game.id;
		return new Promise((resolve, reject) => {
			let db = firebase.firestore();
			this.unsubscribeGroups = db.collection(appConfig.groupsDbName).where('gameId', '==', gameId).onSnapshot(
				(querySnapshot) => {
					let groups = [];
					querySnapshot.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve();// resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					reject(error);
				}
			);
		});
	};

	/**
	 * Create game
	 */
	createGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/create-game', {
				facilitatorId: this.props.userId,
				groupsData: groupsData
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Reset game (develop mode)
	 */
	restartGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/restart-game', {
				facilitatorId: this.props.userId,
				groupsData: groupsData
			}).then(() => {
				resolve({status: 'success'});
			}).catch((error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	};

	/**
	 * Update user (facilitator) data
	 * @param {object} updates 
	 * @returns 
	 */
	updateUser = (updates) => {
		const userId = this.props.userId;
		const db = firebase.firestore();
		const userRef = db.collection('users').doc(userId);
		return userRef.update(updates);
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.game.id;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	/**
	 * Toggle settings popup
	 */
	toggleSettingsPopup = () => {
		const showSettingsPopup = !this.state.showSettingsPopup;
		this.setState({showSettingsPopup});
	};

	/**
	 * Go to game step
	 * @param {string} gameStepId 
	 */
	goToGameStep = (gameStepId) => {
		this.updateGame({gameStepId: gameStepId});
	};

	/**
	 * Select group for presentation
	 * @param {string} groupId 
	 */
	selectGroupForPresentation = (actId, groupId) => {
		/* Get data for presentation */
		let presentationsData = (this.state.game.presentations
			? JSON.parse(JSON.stringify(this.state.game.presentations))
			: {}
		);

		/* Update data for act */
		if (!presentationsData.hasOwnProperty(actId)) {
			presentationsData[actId] = {
				selectedGroupId: groupId,
				feedbackStatus: 'ready',
				finishedGroupIds: []
			};
		} else {
			presentationsData[actId].selectedGroupId = groupId;
		}

		/* Update game */
		this.updateGame({presentations: presentationsData});
	};

	/**
	 * Open / close feedback for presenting group
	 * @param {string} actId 
	 */
	updatePresentationFeedbackStatus = (actId, status) => {
		/* Get data for presentation */
		let presentationsData = (this.state.game.presentations
			? JSON.parse(JSON.stringify(this.state.game.presentations))
			: {}
		);

		if (presentationsData.hasOwnProperty(actId)) {
			/* Update data for act */
			presentationsData[actId].feedbackStatus = status;

			/* Update game */
			this.updateGame({presentations: presentationsData});
		}
	};

	/**
	 * Update selected feedback ids for presentation
	 * @param {string} actId 
	 * @param {string} groupId
	 * @param {number} feedbackId 
	 */
	updateSelectedFeedbackIds = (actId, groupId, feedbackId) => {
		
		/* Get data for presentation */
		let presentationsData = (this.state.game.presentations
			? JSON.parse(JSON.stringify(this.state.game.presentations))
			: {}
		);
				
		/* Update data */
		if (presentationsData.hasOwnProperty(actId)) {
			if (!presentationsData[actId].hasOwnProperty(groupId)) {
				presentationsData[actId][groupId] = {};
			}
			if (!presentationsData[actId].hasOwnProperty('selectedFeedbackIds')) {
				presentationsData[actId]['selectedFeedbackIds'] = {};
			}
			if (!presentationsData[actId]['selectedFeedbackIds'].hasOwnProperty(groupId)) {
				/* First feedback id selected */
				presentationsData[actId]['selectedFeedbackIds'][groupId] = [feedbackId];
			} else {
				const feedbackIndex = presentationsData[actId]['selectedFeedbackIds'][groupId].indexOf(feedbackId);
				if (feedbackIndex >= 0) {
					/* Deselect feedback id */
					presentationsData[actId]['selectedFeedbackIds'][groupId].splice(feedbackIndex, 1);
				} else {
					if (
						presentationsData[actId]['selectedFeedbackIds'][groupId].length < 
							appConfig.numberOfFeedbackOptionsToSelect
					) {
						/* Select feedback id */
						presentationsData[actId]['selectedFeedbackIds'][groupId].push(feedbackId);
					}
				}
			}

			/* Update game */
			this.updateGame({presentations: presentationsData});
		}
	};

	/**
	 * Finish presenting group
	 * @param {string} actId 
	 * @param {string} groupId 
	 */
	finishPresentingGroup = (actId, groupId) => {
		/* Get data for presentation */
		let presentationsData = (this.state.game.presentations
			? JSON.parse(JSON.stringify(this.state.game.presentations))
			: {}
		);

		
		if (presentationsData.hasOwnProperty(actId)) {
			/* Update data for act */
			presentationsData[actId].selectedGroupId = null;
			presentationsData[actId].feedbackStatus = 'ready';
			presentationsData[actId].finishedGroupIds.push(groupId);

			/* Update game */
			this.updateGame({presentations: presentationsData});
		}
	};


	/**
	 * Find the winning group
	 * @returns {Promise}
	 */
	findWinningGroup = () => {
		/* Find winner */
		const winnerId = findWinningGroupId(this.state.game, this.state.groups);

		/* Update game in database */
		return this.updateGame({winningGroupId: winnerId});
	};

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading || !this.state.game || this.state.groups.length === 0) {
			return (
				<Loading 
					loadErrMsg={loginUiTexts.loadingGame}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		/* Game */
		return (
			<>
				<Game 
					isFacilitator={true}
					game={this.state.game}
					groups={this.state.groups}
					goToGameStep={this.goToGameStep}
					selectGroupForPresentation={this.selectGroupForPresentation}
					updatePresentationFeedbackStatus={this.updatePresentationFeedbackStatus}
					updateSelectedFeedbackIds={this.updateSelectedFeedbackIds}
					finishPresentingGroup={this.finishPresentingGroup}
					toggleSettingsPopup={this.toggleSettingsPopup}
					findWinningGroup={this.findWinningGroup}
					updateGame={this.updateGame}
					handleLogout={this.props.handleLogout}
				/>
				{this.state.showSettingsPopup && 
					<PopupSettings 
						restartGame={this.restartGame}
						handleLogout={this.props.handleLogout}
						toggleSettingsPopup={this.toggleSettingsPopup}	
					/>
				}
			</>
		);

	}
}

FacilitatorController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default FacilitatorController;
