import React from 'react';
import PropTypes from 'prop-types';
import { gameUiTexts } from 'data/ui-texts';
import { showsData } from 'data/shows-data';
import { genresData } from 'data/genres-data';
import { actors, instructors, scenographers } from 'data/cast-data';
import './group-badge.scss';


const GroupBadge = ({type, actId, group}) => {
	/* Get selected Casting data if type is set to show cast */
	let castingData = {instructor: null, scenographer: null, actors: null};

	// Getting chosen instructor name
	if (group.instructorId) {
		const instructor = instructors.find((i) => {return i.id === group.instructorId;});
		if (instructor) castingData.instructor = instructor.name;
	}
	// Getting chosen scenographer name
	if (group.scenographerId) {
		const scenographer = scenographers.find((s) => {return s.id === group.scenographerId;});
		if (scenographer) castingData.scenographer = scenographer.name;
	}
	// Getting chosen actor names
	if (group.actors && group.actors.length > 0) {
		const actorList = actors.filter((a) => {
			return (group.actors.some((ga) => {return ga.actorId === a.id;}));
		});
		if (actorList) {
			castingData.actors = [];
			actorList.forEach((actor) => {
				castingData.actors.push({id: actor.id, name: actor.name});
			});
		}
	}
	
	/* Get selected show / genre if available */ 
	let showValue = null;
	let genreValue = null;
	if (group.showId) {
		const showData = showsData.find((s) => {return s.id === group.showId;});
		if (showData) showValue = showData.value;
	}
	if (group.genreId) {
		const genreData = genresData.find((g) => {return g.id === group.genreId;});
		if (genreData) genreValue = genreData.value;
	}

	/* Display mode: null (basic), show & genre or show & genre & cast */
	let displayMode = null;
	if (
		(type === 'sidebar' || type === 'presentation' || type === 'presentation-focus') && 
		(castingData.instructor || castingData.scenographer || castingData.actors)
	) {
		displayMode = 'showAndPeople';
	}
	if (
		!displayMode && 
		(type === 'sidebar' || type === 'presentation' || type === 'presentation-focus') && 
		(showValue || genreValue)
	) {
		displayMode = 'showAndGenre';
	}


	return (
		<div 
			className={'GroupBadge ' + type + (group.isPlaying ? ' active' : '') 
				+ (displayMode ? ' ' + displayMode : '')
				+ (actId ? ' ' + actId : '')
			}
		>
			{/* Group logo */}
			<div className={'GroupBadge-logo ' + group.groupId} />

			
			{displayMode && <div className="GroupBadge-wrap">
				{/* Show */}
				<div className={'GroupBadge-section show show-' + group.showId} >
					<div className='GroupBadge-sectionValue'>
						<span>{showValue}</span>
					</div>
				</div>
				{/* Genre */}
				<div className={'GroupBadge-section genre genre-' + group.genreId}>
					<div className='GroupBadge-sectionLabel'>
						<span>{gameUiTexts.as + ': '}</span>
					</div>
					<div className='GroupBadge-sectionValue'>
						<span>{genreValue}</span>
					</div>
				</div>

					
				{displayMode === 'showAndPeople' && <>
					{/* Instructor */}
					<div className='GroupBadge-section instructor'>
						<div className='GroupBadge-sectionLabel'>
							<span>{gameUiTexts.instructedBy + ': '}</span>
						</div>
						<div className='GroupBadge-sectionValue'>
							<span>{castingData.instructor ? castingData.instructor : ''}</span>
						</div>
					</div>
					{/* Scenographer */}
					<div className='GroupBadge-section scenographer'>
						<div className='GroupBadge-sectionLabel'>
							<span>{gameUiTexts.scenographyBy + ': '}</span>
						</div>
						<div className='GroupBadge-sectionValue'>
							<span>{castingData.scenographer ? castingData.scenographer : ''}</span>
						</div>
					</div>
					{/* Actors */}
					<div className='GroupBadge-section actors'>
						<div className='GroupBadge-sectionLabel'>
							<span>{gameUiTexts.with + ': '}</span>
						</div>
						{castingData.actors &&
								castingData.actors.map((actor, index) => {
									return (
										<div 
											key={index} 
											className={'GroupBadge-sectionValue actor-' + actor.id}
										>
											<span>{actor.name}</span>
										</div>
									);
								})
						}
					</div>
				</>}
			</div>}
		</div>
	);
};

GroupBadge.propTypes = {
	type: PropTypes.string.isRequired,
	actId: PropTypes.string,
	group: PropTypes.object.isRequired
};

export default GroupBadge;