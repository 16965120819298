import React from 'react';
import './title-logo.scss';
import PropTypes from 'prop-types';


const TitleLogo = ({hasRadial = true}) => {
	return (
		<div className='TitleLogo'>
			{hasRadial && <div className='TitleLogo-radialGradient'/>}
			<div className='TitleLogo-titleLogo'/>
		</div>
	);
};

TitleLogo.propTypes = {
	hasRadial: PropTypes.bool,
};

export default TitleLogo;