const actsData = [
	{
		id: 'act-1',
		title: '1. Akt',
		subtitle: 'Repertoire'
	},
	{
		id: 'act-2',
		title: '2. Akt',
		subtitle: 'Iscenesættelse'
	},
	{
		id: 'act-3',
		title: '3. Akt',
		subtitle: 'Prøveforløb'
	}
];
export {
	actsData
};