import {sortArrayByProperty} from './array-helper';

/**
 * Get presentation data (selected group, feedback status) for specific act
 * @param {string} actId 
 * @param {object} game 
 * @returns 
 */
function getPresentationData(actId, game) {
	let presentationData = null;
	if (game && game.presentations && game.presentations.hasOwnProperty(actId)) {
		presentationData = JSON.parse(JSON.stringify(game.presentations[actId]));
	}
	return presentationData;
}

/**
 * Get selected feedback ids for presenting groups in specific act
 * @param {bool} isFacilitator 
 * @param {string} actId 
 * @param {object} presentationData 
 * @param {object} group 
 * @returns 
 */
function getSelectedFeedbackIds(isFacilitator, actId, presentationData, group) {
	/* No group is presenting */
	if (!presentationData || !presentationData.selectedGroupId) return [];

	/* Facilitator */
	if (isFacilitator) {
		if (
			presentationData.selectedFeedbackIds && 
			presentationData.selectedFeedbackIds.hasOwnProperty(presentationData.selectedGroupId)
		) {
			return presentationData.selectedFeedbackIds[presentationData.selectedGroupId];
		}
		return [];
	}

	/* Group */
	if (
		group && 
		group.selectedFeedbackIds &&
		group.selectedFeedbackIds.hasOwnProperty(actId) &&
		group.selectedFeedbackIds[actId].hasOwnProperty(presentationData.selectedGroupId)
	) {
		return group.selectedFeedbackIds[actId][presentationData.selectedGroupId];
	}
	return [];
}

/**
 * Get feedback result
 * @param {string} actId 
 * @param {array} feedbackOptions 
 * @param {object} presentationData 
 * @param {array} groups 
 * @returns 
 */
function getFeedbackResult(actId, feedbackOptions, presentationData, groups) {
	/* No group is presenting / feedback status is not closed */
	if (
		!presentationData || 
		!presentationData.selectedGroupId || 
		presentationData.feedbackStatus !== 'closed'
	) return [];

	/* Get alle available feedback option ids */
	let feedbackIds = feedbackOptions.map((option) => {return {id: option.id, selectedBy: 0};});
	
	/* Feedback selected by facilitator */
	const facilitatorSelectedIds = getSelectedFeedbackIds(true, actId, presentationData, null);
	facilitatorSelectedIds.forEach((selectedId) => {
		const feedbackIndex = feedbackIds.findIndex((feedback) => {return feedback.id === selectedId;});
		if (feedbackIndex >= 0) feedbackIds[feedbackIndex].selectedBy += 1;
	});

	/* Feedback selected by groups */
	groups.forEach((group) => {
		const groupSelectedIds = getSelectedFeedbackIds(false, actId, presentationData, group);
		groupSelectedIds.forEach((selectedId) => {
			const feedbackIndex = feedbackIds.findIndex((feedback) => {return feedback.id === selectedId;});
			if (feedbackIndex >= 0) feedbackIds[feedbackIndex].selectedBy += 1;
		});
	});

	/* Filter unselected feedbackIds */
	feedbackIds = feedbackIds.filter((feedback) => {return feedback.selectedBy > 0;});

	/* Sort feedbackIds by most popular */
	feedbackIds = sortArrayByProperty(feedbackIds, 'selectedBy', 'DESC');

	/* Sort by least popular */
	feedbackIds = sortArrayByProperty(feedbackIds, 'selectedBy', 'ASC');

	return feedbackIds;
};

export {
	getPresentationData,
	getSelectedFeedbackIds,
	getFeedbackResult
};