const reviewsData = {
	/* Rewarded stars depens on number of active groups */
	stars: [
		{
			numberOfGroups: 6,
			stars: [6, 5, 5, 4, 4, 3]
		},
		{
			numberOfGroups: 5,
			stars: [6, 5, 4, 4, 3]
		},
		{
			numberOfGroups: 4,
			stars: [6, 5, 4, 3]
		},
		{
			numberOfGroups: 3,
			stars: [6, 4, 3]
		},
	],

	/* Title and blurb depends on rewarded stars */
	specificTitleBlurbEnding: [
		{
			numberOfStars: [3, 4],
			texts: [
				{
					title: `%showName% snubler på målstregen`, 
					blurb: `Den nyeste forestilling fra %instructorName% stræber efter det fremragende, men for mange skønhedsfejl ødelægger helhedsbilledet.`,
					ending: `Samlet set ender vi på %numberOfStars% stjerner og en tro på at det næste gang bliver virkeligt godt.`
				}, 
				{
					title: `Har %instructorName% mistet grebet?`,
					blurb: `%actor1Name% og %actor2Name% er fremragende i ellers gennemsnitlig forestilling.`,
					ending: `%instructorName% har måske ikke helt fast nok fat om roret, men vi sejler stabilt igennem de rørte vande.`
				},
				{
					title: `Medrivende børneteater`,
					blurb: `Det er svært ikke at blive revet med i forestilling %showName%, men %instructorName% har valgt for mange nemme løsninger.`,
					ending: `Alt i alt blev jeg revet med af forestillingen og så kan man måske leve med de nemme løsninger.`
				}
			]
		},
		{
			numberOfStars: [5, 6],
			texts: [
				{
					title: `%groupName% leverer endnu et hit`,
					blurb: `I et hæsblæsende tempo, viser %instructorName% hvor skabet skal stå i moderne teater.`,
					ending: `%showName% ender med at stille skabet så grundigt på plads at der kommer til at gå en rum tid før nogen får flyttet det igen.`
				},
				{
					title: `%showName% er teatermagi`,
					blurb: `Med %instructorName% har %groupName% fået en alkymist der spinder guld ud af det begrænsede matriale.`,
					ending: `Lad os håbe at dette ikke bliver den sidste forestilling med %instructorNamePossessive% guldspinnerier.`
				},
				{
					title: `Unikke øjeblikke i %groupName%`,
					blurb: `Tiden stod stille under denne sublime opsætning af %showName%.`,
					ending: `Tilbage på redaktionen begyndte tiden igen at gå. Mens jeg skriver denne anmeldelse funderer jeg stille over, hvor meget tid kan være værd når den blot bruges rigtigt.`
				}
			]
		},
	],
	randomTitleBlurbEnding: [
		{
			title: `%instructorName% krydser sit spor`,
			blurb: `Flere fantastiske greb gør %showName% på en gang personligt vedrørende for publikum og autobiografisk for %instructorName%.`,
			ending: `Uanset hvad man synes om stykket, er det svært ikke at bliver berørt af den personlige vinkel.`
		},
		{
			title: `Børneteater til tiden`,
			blurb: `%instructorName% leverer unik oplevelse til store og små mennesker.`,
			ending: `Dette unikke stykke husker én på hvad det er teater kan, og hvorfor alle bør tage deres børn med i teateret.`
		},
		{
			title: `Personligt twist på klassisk fortælling`,
			blurb: `Dybt original opsætning af %showName.`,
			ending: `Slutteligt skal jeg nævne at selv om forestillingen måske ikke appelerer til alle, så er der bestemt noget at komme efter; om ikke andet så for at se noget så originalt.`
		}
	],

	/* Main part 1 depends on number of stars and selected instructor (and maybe genre) */
	mainPart1: [
		{
			numberOfStars: [3, 4],
			options: [
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 1} // Helle kvidrum
					],
					text: `%instructorName% er her i sin karrierers efterår i gang med at genopfinde sig selv. Selvom hun ikke kommer helt i mål med denne opsætning af %showName% beviser hun at det på ingen måder slut med hendes genialiteter.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 2} // Jhørn Hanzennnnn
					],
					text: `Under Hanzennnnns kyndige instruktion bliver %showName% iscenesat med en gennemgående undertekst om magiens fravær i moderne teater. Ikke mange  kan gøre det så subtilt. Beklageligvis betyder det også at selve stykket er på grænsen til at drukne i banaliteter. `
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 3}, // Anders Madsen
						{type: 'propertyIs', propertyName: 'genreId', propertyValue: 4}, // comedy
					],
					text: `At %instructorName% er en sjov mand hersker der ingen tvivl om. Med forestillingen %showName% viser han desværre også at selv om noget er populært, er det ikke nødvendigvis fantastisk. Det virker som om komediegenren har udspillet sin rolle for Madsen.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 3}, // Anders Madsen
						{type: 'propertyIsNot', propertyName: 'genreId', propertyValue: 4}, // not comedy
					],
					text: `At %instructorName% er en sjov mand hersker der ingen tvivl om. Med forestillingen %showName% viser han desværre også at selv om noget er populært, er det ikke nødvendigvis fantastisk. Man må undre sig over hvorfor Madsen har valgt at skifte sin sikre genre, komedien, ud med %genreName%. Men han skal da have point for at prøve noget nyt.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 4}, //Pauline Show
					],
					text: `Åh hvilket show! Men det er så også netop dét det er, et show. Et spektakulært stykke, hvor der er mere glasuer og pynt på kagen end solidt konditorhåndværk. Det er som om %instructorName% ikke tør tage det filmiske håndværk, hun har i baggagen, i brug i teaterverdenen.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 5}, // Louise Lange
					],
					text: `Jeg blev personligt overrasket da jeg hørte at %instructorName% skulle instruere %showName% og sætte det op som %genreName%. På trods af det noget mangelfulde resultat, må jeg bøje mig i støvet og sige at det var et visionært valg.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 6}, // Pelle Halsløs
					],
					text: `Den 17 årige wunderkind %instructorName% har indtil videre ikke kunnet sætte en fod forkert. Med hans trods alt begrænsede erfaring har han nok gabt over for meget med %showName%. Og det i en sådan grad at valget af instruktør fremstår som en halsløs gerning.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 7}, // Denice De la Bonbon
					],
					text: `I en eklektisk tour de force takler den tidligere gøgler, model, stewardesse og delfinpasser, %instructorName%, %showName% så flot at man næsten glemmer at hun fæller spilleren. Forestillingen emmer af inspiration, men kommer aldrig helt i mål.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 8}, // Victor Vågen
					],
					text: `%instructorName% har alle dage repræsenteret de klassiske dyder på den danske teaterscene. I %showName% vover han heller ikke denne gang pelsen. Det er det sikre og stabile håndværk vi er vidne til og det tenderer det kedelige.`
				},
			]
		},
		{
			numberOfStars: [5, 6],
			options: [
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 1} // Helle kvidrum
					],
					text: `%instructorName% er her i sin karrierers efterår i gang med at genopfinde sig selv. I denne opsætning af %showName% beviser hun at det på ingen måder slut med hendes genialiteter.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 2} // Jhørn Hanzennnnn
					],
					text: `Under Hanzennnnns kyndige instruktion bliver %showName% iscenesat med en gennemgående undertekst om magiens fravær i moderne teater.  Ikke mange kan gøre dette så subtilt. Stykket åbner sig på magisk vis for publikum som en babushkadukke med lag på lag af betydninger.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 3}, // Anders Madsen
						{type: 'propertyIs', propertyName: 'genreId', propertyValue: 4}, // comedy
					],
					text: `At %instructorName% er en sjov mand hersker der ingen tvivl om. Men med forestillingen %showName% beviser han at hans tallenter rækker så langt ud over det åbenlyst sjove.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 3}, // Anders Madsen
						{type: 'propertyIsNot', propertyName: 'genreId', propertyValue: 4}, // not comedy
					],
					text: `At %instructorName% er en sjov mand hersker der ingen tvivl om. Med forestillingen %showName% tilføjer han nye dimensioner til komikkens væsen.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 4}, //Pauline Show
					],
					text: `Åh hvilket show! Et spektakulært stykke - som en fuldent kondititorkage - fantastisk flot, svært at udføre og forførende at sætte tænderne i.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 5}, // Louise Lange
					],
					text: `Jeg blev personligt overrasket da jeg hørte at %instructorName% skulle instruere %showName% og sætte det op som %genreName%. Men da tæppet gik til side, faldt alle forbehold til jorden. Jeg må bøje mig i støvet og sige at det var et visionært valg.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 6}, // Pelle Halsløs
					],
					text: `Den 17 årige wunderkind %instructorName% har indtil videre ikke kunnet sætte en fod forkert. Med hans trods alt begrænsede erfaring kunne man foranlediges til at tro at han måske havde gabt over for meget med %showName%. Sjældent ville man have taget mere fejl. En sublim opsætning, ikke mindst det originale valg at benytte %genreName%-genren som ramme.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 7}, // Denice De la Bonbon
					],
					text: `En eklektisk tour de force af et teaterstykke. I %showName% rammer den tidligere gøgler, model, stewardesse og delfinpasser, Denice De la Bonbon, bolden så rent at man rent glemmer hverdagens problemer. En rejse med %genreName%-genrens twist som tourguide.`
				},
				{
					conditions: [
						{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 8}, // Victor Vågen
					],
					text: `%instructorName% har alle dage repræsenteret de klassiske dyder på den danske teaterscene. I %showName% vover han heller ikke denne gang for alvor pelsen. Det er det sikre og stabile håndværk vi er vidne til her. Men det tenderer aldrig det kedelige.`
				},
			]
		}
	],

	/* Main part 2 depends on show */
	mainPart2: [
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 1}], // Brødrene Løvehjerte
			text: `%actor1Name% leverer en helhjertet præstation som Karl Tvebak i et intenst sammenspil med %actor2Name%.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 2}], // Den grimme ælling
			text: `Som skønheden in spe leverer %actor1Name% sit livs præstation, men stjæler desværre helt rampelyset fra sine ellers stabilt gode, medspillere.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 3}], // Klods Hans
			text: `Tre-kløveret Klods Hans, Broderen og Prinsessen (spillet af henholdsvis %actor1Name%, %actor2Name% og %actor3Name%) udgør et vægtigt fokus for publikums opmærksomhed hele vejen igennem forestillingen.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 4}], // Løvernes Konge
			text: `Timon og Pumba, spillet af %actor2Name% og %actor3Name%, er som funklende og urkomiske stjerner under den malede afrikanske himmelhvælving.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 5}], // Frost
			text: `%actor1Name% og %actor3Name% spiller med en iskold kemi der på et metanarativt plan tematiserer den fremmedgørelse Elsa føler.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 6}], // Spiderman
			text: `I rollen som Spiderman stopper %actor1Name% tyvene, og han stjæler alle vores hjerter.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 7}], // Star Wars
			text: `Vi er i gang med de store klicheer her, men med %actor3Name%'s Lord Vader får man lyst til at udbryde: ond, ondere, Darth Vader. `
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'showId', propertyValue: 8}], // Harry Potter
			text: `Mange, inklusiv forfatteren selv, har hævdet at Harry og Hermione måske skulle ende sammen. Det er imidlertid ikke den vej iscenesættelsen tager. I rollerne følger vi %actor1Name% som Harry og %actor2Name% som Hermione, i deres indædte kamp mod Dolores Nidkjær (spillet af %actor3Name%). Her levnes ikke meget plads til nogen romantisk subtekst.`
		}
	],

	/* Main part 3 depends on instroctor and/or scenographer */
	mainPart3: [
		{
			conditions: [
				{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 1}, // Helle Kvidrum
				{type: 'propertyIs', propertyName: 'scenographerId', propertyValue: 2}, // Lotte Blåmus
			],
			text: `Vi skal ikke glemme scenografien hvor den ene halvdel af scenen er i sort-hvid mens den anden er enormt lav. Undertegnede kan ikke gennemskue hvorfor.`
		},
		{
			conditions: [
				{type: 'propertyIs', propertyName: 'instructorId', propertyValue: 1}, // Helle Kvidrum
				{type: 'propertyIsNot', propertyName: 'scenographerId', propertyValue: 2}, // not Lotte Blåmus
			],
			text: `Vi skal ikke glemme den sort hvide scenografi der skaber en stemningsmættet ramme om fortællingen.`
		},
		{
			conditions: [
				{type: 'propertyIsNot', propertyName: 'instructorId', propertyValue: 1}, // not Helle Kvidrum
				{type: 'propertyIs', propertyName: 'scenographerId', propertyValue: 2}, // Lotte Blåmus
			],
			text: `Vi skal ikke glemme scenografien hvor %scenographerName% har skabt en enormt lav scenografi der får skuespillerne til at fremstå som kæmper.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'scenographerId', propertyValue: 7}], // Rebecca Costello
			text: `Endelig må vi ikke glemme scenografiens gennemgående regnbuetema der giver hele opsætningen et håbefuldt twist.`
		},
		{
			conditions: [{type: 'propertyIs', propertyName: 'scenographerId', propertyValue: 5}], // Ulla Vildsen 
			text: `Jeg er nød til at nævne %scenographerName%s kostumer. Ingen anden kan lave noget så klassisk stilrent og så gennemført kaotisk på samme tid.`
		}
	]
};
export {
	reviewsData
};