import React from 'react';
import PropTypes from 'prop-types';
import GroupBadge from 'components/ui/group-badge/group-badge';
import './group-login.scss';

const GroupLogin = ({groups}) => {
	return (
		<div className="GroupLogin">
			<div className="GroupLogin-groups">
				{groups.map((group) => {
					return (
						<div key={group.id} className="GroupLogin-group">
							<GroupBadge type="group-login" group={group} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

GroupLogin.propTypes = {
	groups: PropTypes.array.isRequired
};

export default GroupLogin;