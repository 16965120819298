import './company-logos.scss';

const CompanyLogos = () => {

	return (
		<div className='CompanyLogos'>
			<div className='CompanyLogos-danskTeater'/>
			<div className='CompanyLogos-teaterCentrum'/>
			<div className='CompanyLogos-apMollerFonden'/>
			<div 
				className='CompanyLogos-cgl' 
				onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}
			/>
			
		</div>
	);
};

export default CompanyLogos;