import appConfig from 'config/app.config';
import {gameStepsData} from 'data/game-steps-data';
import {groupsData} from 'data/groups-data';
import {gameUiTexts} from 'data/ui-texts';

/**
 * Get groups that have not given feedback
 * @param {string} actId 
 * @param {object} game 
 * @param {array} groups 
 */
function getMissingFeedback(actId, game, activeGroups) {
	/* Prepare list of mising feedback */
	let missingFeedbackIds = [];

	/* Get data for presentation */
	const presentationsData = (game.presentations
		? JSON.parse(JSON.stringify(game.presentations))
		: {}
	);
	if (presentationsData.hasOwnProperty(actId)) {
		/* Get id of presenting group */
		const groupId = presentationsData[actId].selectedGroupId;

		/* Check if facilitator has given feedback */
		if (
			!presentationsData[actId]['selectedFeedbackIds'] ||
			!presentationsData[actId]['selectedFeedbackIds'].hasOwnProperty(groupId) ||
			presentationsData[actId]['selectedFeedbackIds'][groupId].length < appConfig.numberOfFeedbackOptionsToSelect
		) {
			/* Facilitator has not given feedback */
			missingFeedbackIds = ['facilitator'];
		} else {
			/* Check if all groups have given feedback (only if facilitator has given feedback) */
			activeGroups.forEach((group) => {
				if (
					group.id !== groupId  &&
					(
						!group.hasOwnProperty('selectedFeedbackIds') ||
						!group.selectedFeedbackIds.hasOwnProperty(actId) ||
						!group.selectedFeedbackIds[actId].hasOwnProperty(groupId) ||
						group.selectedFeedbackIds[actId][groupId].length < appConfig.numberOfFeedbackOptionsToSelect
					)
				) {
					/* Group has not given feedback (and is not the presenting group) */
					missingFeedbackIds.push(group.groupId);
				}
			});
		}
	}
	return missingFeedbackIds;
}

/**
 * Get ids of groups that have not completed selection task (choose show/genre or casting)
 * @param {array} optionIds 
 * @param {array} activeGroups 
 */
function getGroupsThatHaveNotCompletedSelectionTask(optionIds, activeGroups) {
	/* Prepare list of group ids */
	let incompletedGroupIds = [];

	/* Check each active groups */
	activeGroups.forEach((group) => {
		let hasCompletedTask = true;
		optionIds.forEach((optionId) => {
			if (!hasCompletedTask) return;
			if (optionId === 'actors') {
				if (
					!group.hasOwnProperty(optionId) || 
					!group[optionId] || 
					group[optionId].length < appConfig.numberOfActorsToSelect
				) {
					hasCompletedTask = false;
				}
			} else {
				if (!group.hasOwnProperty(optionId) || !group[optionId]) {
					hasCompletedTask = false;
				}
			}
		});
		if (!hasCompletedTask) incompletedGroupIds.push(group.groupId);
	});

	/* Return list */
	return incompletedGroupIds;
}

/**
 * Get action and params for next step button
 * @param {string} gameStepId 
 * @param {object} game 
 * @param {array} groups 
 * @param {function} goToGameStep 
 * @param {function} updatePresentationFeedbackStatus 
 * @param {function} finishPresentingGroup 
 * @returns 
 */
function getNextStepBtnActionAndParams(
	gameStepId, 
	game, 
	groups, 
	goToGameStep, 
	updatePresentationFeedbackStatus, 
	finishPresentingGroup,
	setPopupData,
	updateGame
) {
	/* Get index of current game step */
	const currentGameStepIndex = gameStepsData.findIndex((gs) => {return gs.id === gameStepId;});
	const currrentGameStepData = gameStepsData[currentGameStepIndex];

	/* Get next game step id */
	const nextGameStepId = (currentGameStepIndex + 1 < gameStepsData.length
		? gameStepsData[currentGameStepIndex + 1].id
		: null
	);

	/* Default: go to next game step */
	let nextStepText = (currrentGameStepData.nextStepBtnText ? currrentGameStepData.nextStepBtnText : gameUiTexts.next);
	let nextStepAction = (nextGameStepId ? goToGameStep : null);
	let nextStepParams = [nextGameStepId];	
	let nextStepClasses = ['next'];

	/* Get active groups */
	const activeGroups = groups.filter((g) => {return g.isPlaying === true;});


	/* Special case: choose show/genre or casting */
	if (currrentGameStepData.type === 'choose-show-and-genre' || currrentGameStepData.type === 'casting') {
		const optionIds = (currrentGameStepData.type === 'choose-show-and-genre'
			? ['showId', 'genreId']
			: ['instructorId', 'scenographerId', 'actors']
		);
		const incompletedGroupIds = getGroupsThatHaveNotCompletedSelectionTask(optionIds, activeGroups);
		if (incompletedGroupIds.length > 0) {
			/* One or more groups are not ready, show warning popup */
			let popupText = gameUiTexts.groupsNotDoneChoosingWarningPopup.text + '<br />';
			incompletedGroupIds.forEach((groupId, index) => {
				const groupData = groupsData.find((g) => {return g.id === groupId;});
				if (groupData) {
					popupText += groupData.name;
					if (index + 1 < incompletedGroupIds.length) popupText += '<br />';
				}
			});
			nextStepAction = setPopupData;
			nextStepParams = [{
				title: gameUiTexts.groupsNotDoneChoosingWarningPopup.title,
				text: popupText,
				type: 'warning',
				buttons: [
					{
						text: gameUiTexts.groupsNotDoneChoosingWarningPopup.button,
						classes: ['prev'],
						action: setPopupData,
						parameters: [null]
					},
					{
						closePopup: true,
						text: gameUiTexts.next,
						classes: ['next'],
						action: goToGameStep,
						parameters: [nextGameStepId]
					}
				]
			}];
		}
	}

	/* Special case: Presentation */
	if (gameStepId === 'performance') nextStepClasses.push('small-text');
	if (currrentGameStepData.type === 'presentation' && activeGroups.length > 0) {
		if (
			game && 
			game.presentations && 
			game.presentations.hasOwnProperty(currrentGameStepData.actId)
		) {
			const presentationData = game.presentations[currrentGameStepData.actId];
			if (presentationData.selectedGroupId) {
				nextStepText = gameUiTexts.next;

				/* Group is presenting */
				if (!presentationData.feedbackStatus || presentationData.feedbackStatus === 'ready') {
					/* Next step: open for feedback */
					nextStepText = gameUiTexts.evaluation;
					nextStepAction = updatePresentationFeedbackStatus;
					nextStepParams = [currrentGameStepData.actId, 'open'];
					nextStepClasses.push('small-text');
				}
				if (presentationData.feedbackStatus === 'open') {
					/* Get groups that have not yet given feedback */
					const missingFeedback = getMissingFeedback(
						currrentGameStepData.actId, game, activeGroups
					);
					if (missingFeedback.length > 0) {
						/* Facilitator or one or more gropus have not given feedback, show warning popup */
						let popupText = null;
						if (missingFeedback[0] === 'facilitator') {
							popupText = gameUiTexts.presentationWarningPopup.text1;
						} else {
							popupText = gameUiTexts.presentationWarningPopup.text2 + '<br />';
							missingFeedback.forEach((groupId, index) => {
								const groupData = groupsData.find((g) => {return g.id === groupId;});
								if (groupData) {
									popupText += groupData.name;
									if (index + 1 < missingFeedback.length) popupText += '<br />';
								}
							});
						}
						
						nextStepAction = setPopupData;
						nextStepParams = [{
							title: gameUiTexts.presentationWarningPopup.title,
							text: popupText,
							type: 'warning',
							buttons: [
								{
									text: gameUiTexts.presentationWarningPopup.button,
									classes: ['prev'],
									action: setPopupData,
									parameters: [null]
								},
								{
									closePopup: true,
									text: gameUiTexts.next,
									classes: ['next'],
									action: updatePresentationFeedbackStatus,
									parameters: [currrentGameStepData.actId, 'closed']
								}
							]
						}];
					} else {
						/* Next step: close for feedback */
						nextStepAction = updatePresentationFeedbackStatus;
						nextStepParams = [currrentGameStepData.actId, 'closed'];
					}
				}
				if (presentationData.feedbackStatus === 'closed') {
					/* Next step: end group presentation */
					nextStepAction = finishPresentingGroup;
					nextStepParams = [currrentGameStepData.actId, presentationData.selectedGroupId];
				}
			} else {
				
				/* No groups are presenting */
				if (
					!presentationData || 
					!presentationData.finishedGroupIds || 
					presentationData.finishedGroupIds.length < activeGroups.length
				) {
					/* Not all groups have presented, do not show next btn*/
					nextStepAction = null;
				}
			}
		} else {
			nextStepAction = null;
		}
	}

	/* Special case: reviews */
	if (gameStepId === 'reviews' && game && game.winningGroupId && !game.showReviews) {
		nextStepAction = updateGame;
		nextStepParams = [{showReviews: true}];
	}

	/* Return action and params */
	return {nextStepText, nextStepAction, nextStepParams, nextStepClasses};
};


export {
	getMissingFeedback,
	getNextStepBtnActionAndParams
};