import ChooseShow from "components/choose-show/choose-show";
import Intro from "components/intro/intro";
import GroupLogin from "components/group-login/group-login";
import Assignment from "components/assignment/assignment";
import Presentation from "components/presentation/presentation";
import ChooseShowFacilitator from "components/choose-show-facilitator/choose-show-facilitator";
import Attention from "components/attention/attention";
import CastingFacilitator from "components/casting-facilitator/casting-facilitator";
import Casting from "components/casting/casting";
import Finish from "components/finish/finish";

const gameStepsData = [
	{
		id: 'group-login',
		actId: null,
		type: 'group-login',
		facilitatorComponent: GroupLogin,
		groupComponent: Attention,
		showDownloadMaterialsBtn: true,
		title: 'Elev login',
		facilitatorInstructions: `Inddel eleverne i 6 grupper.
			<br /><br />Fordel skiltene med de seks teatre (som du forinden har printet og foldet) mellem grupperne og bed hver gruppe læse teksten til deres teater højt for klassen.
			<br /><br />Hver gruppe går nu ind på <strong>%GAMEURL%</strong> og logger ind med denne spilkode: <strong>%GAMECODE%</strong> og vælger deres teater.
			<br /><br />Klik "1. AKT", når alle grupper er logget ind.`,
		groupInfo: 'Følg med på lærerskærmen',
		nextStepBtnText: '1. AKT'
	},
	{
		id: 'intro',
		actId: 'act-1',
		facilitatorComponent: Intro,
		groupComponent: Attention,
		title: 'Intro',
		groupInfo: 'Følg med på lærerskærmen',
		videoId: 'LAJzR3Sz7xo',
	},
	{
		id: 'show-and-genre',
		actId: 'act-1',
		type: 'choose-show-and-genre',
		facilitatorComponent: ChooseShowFacilitator,
		groupComponent: ChooseShow,
		title: 'Vælg stykke og genre',
		groupInstructions: `I skal nu vælge en forestilling og hvilken genre jeres forestilling skal have. Tænk over spørgsmålene herunder og vælg så hvilken genre jeres teaterforestilling skal have.
		<br /><br />- Hvilken genre eller stemning passer bedst til jeres teater?
		<br />- Hvilken stemning skal publikum føle når de ser forestillingen?
		<br />- Hvilken genre har i lyst til at bruge?`,
		facilitatorInstructions: `Hver gruppe skal nu vælge forestilling og den genre den skal spilles i. 
			<br /><br />Hver forestilling kan kun vælges af én gruppe, men flere grupper kan godt arbejde med den samme genre.`
	},
	{
		id: 'poster-assignment',
		actId: 'act-1',
		facilitatorComponent: Assignment,
		groupComponent: Assignment,
		title: 'Idé og plakat',
		facilitatorInstructions: `Grupperne skal nu udvikle et koncept for forestillingen og illustrere det med en plakat. Med udgangspunkt i plakaten skal de præsentere deres overvejelser om, hvordan de vil fortælle deres historie, herunder betydningen af genrevalg. 
			<br /><br />Giv grupperne ca. 40 minutter til at lave plakaten og forberede deres præsentation.
			<br /><br />Klik videre, når alle grupper er klar til at præsentere.`,
		assignmentTitle: 'Opgave 1',
		assignmentSubtitle: 'Idé og plakat',
		assignmentText: `I har valgt forestilling og genre. Nu skal I finde ud af hvordan I vil sætte stykket op, og lave en plakat der viser jeres idé.
			<br /><br />På plakaten skal der være:
			<ol>
			<li>Jeres teaters navn</li>
			<li>Forestillingens navn</li>
			<li>En eller flere hovedpersoner</li>
			<li>Noget der viser jeres ide med forestillingen</li>
			</ol>`
	},
	{
		id: 'presentation',
		actId: 'act-1',
		type: 'presentation',
		facilitatorComponent: Presentation,
		groupComponent: Presentation,
		title: 'Præsentation',
		facilitatorInstructions: `Vælg den gruppe der skal fremlægge først.`,
		facilitatorInstructions2: `Vælg den næste gruppe der skal fremlægge.`,
		facilitatorInstructions3: `Klik videre til bedømmelse, når gruppen har præsenteret.`,
		facilitatorInstructions4: `Du og de andre grupper skal nu bedømme fremlæggelsen. I skal vælge to udsagn.
			<br /><br />Klik videre, når alle grupper har bedømt.`,
		feedbackOptions: [
			{id: 1, text: 'Spændende måde at fortælle historien på', points: 2},
			{id: 2, text: 'Ideen er god, men også svær', points: 1},
			{id: 3, text: 'Lige noget for mine forældre', points: 1},
			{id: 4, text: 'Fed plakat', points: 2},
			{id: 5, text: 'Cool, men der er måske brug for nogle ændringer', points: 1},
			{id: 6, text: 'Jeg forstår det ikke', points: 0},
			{id: 7, text: 'Jeg troede, det var en kedelig historie, men det her bliver sjovt', points: 2},
			{id: 8, text: 'Sjovt, men også lidt langt ude', points: 1},
		],
		nextStepBtnText: '2. AKT'
	},
	{
		id: 'intro-2',
		actId: 'act-2',
		facilitatorComponent: Intro,
		groupComponent: Attention,
		title: 'Intro',
		groupInfo: 'Følg med på lærerskærmen',
		videoId: 'vvT9C4Ll7CA'
	},
	{
		id: 'casting',
		actId: 'act-2',
		type: 'casting',
		title: 'Casting',
		facilitatorComponent: CastingFacilitator,
		groupComponent: Casting,
		groupInfo: 'Casting',
		facilitatorInstructions: `Grupperne skal nu caste deres stykker.
		<br /><br />Giv klassen ca. 15 minutter til gå op og læse om de forskellige aktører (som du på forhånd har printet og sat op på væggen i klasseværelset). Mind dem om at læse alt hvad der står om aktørerne og tænke over, hvem der vil passe bedst til deres stykke og genre, og hvem der vil samarbejde godt.
		<br /><br />Hver aktør kan kun vælges af én gruppe, så de bør også overveje backup valg.
		<br /><br />Herefter skiftes grupperne til at vælge en aktør, indtil alle grupper har en instruktør, en scenograf og tre skuespillere.
		<br /><br />Bed så grupperne hente printene med deres cast ned til deres bord og tryk videre.`
	},
	{
		id: 'interview-assignment',
		actId: 'act-2',
		facilitatorComponent: Assignment,
		groupComponent: Assignment,
		title: 'Dramaturgi og scenografi',
		facilitatorInstructions: `Giv grupperne ca. 20 minutter til at forberede sig på at blive interviewet.`,
		assignmentTitle: 'Opgave 2',
		assignmentSubtitle: 'Dramaturgi og scenografi',
		assignmentText: `I er blevet inviteret til et TV-interview, hvor I skal fortælle om jeres planer for forestillingen. Jeres mål er at få en masse mennesker til at have lyst til at se jeres stykke. Forbered jer på at besvare nedenstående spørgsmål (jeres lærer vil interviewe jer).
			<ul>
			<li>Vil I ændre ved historien - I så fald hvordan?</li>
			<li>Hvordan skal scenografien se ud?</li>
			<li>Hvordan vil I udnytte de ting jeres cast er bedst til?</li>
			</ul>`
	},
	{
		id: 'interview',
		actId: 'act-2',
		type: 'presentation',
		facilitatorComponent: Presentation,
		groupComponent: Presentation,
		showAudience: true,
		title: 'Interview',
		facilitatorInstructions: `Grupperne vælger to personer som kommer op og bliver interviewet (sæt stole op). Interviewene holdes på ca. to minutter.
			<br /><br />Vælg den gruppe der skal interviewes først.`,
		facilitatorInstructions2: `Grupperne vælger to personer som kommer op og bliver interviewet (sæt stole op). Interviewene holdes på ca. to minutter.
			<br /><br />Vælg den næste gruppe der skal interviewes.`,
		facilitatorInstructions3: `Interview-spørgsmål:
			<ul>
			<li>Vil I ændre ved historien - i så fald hvordan?</li>
			<li>Hvordan skal scenografien se ud?</li>
			<li>Hvordan vil i udnytte de ting jeres cast er bedst til?</li>
			<li>Og hvad der ellers falder dig ind at spørge ind til ...</li>
			</ul>
			<br />Klik videre til bedømmelse efter interviewet.`,
		facilitatorInstructions4: `Du og de andre grupper skal nu bedømme interviewet. I skal vælge to udsagn. 
			<br /><br />Klik videre, når alle grupper har bedømt.`,

		feedbackOptions: [
			{id: 1, text: 'Gjorde mig nysgerrig', points: 1},
			{id: 2, text: 'Hvis de gør sig umage, kan det blive virkelig godt', points: 2},
			{id: 3, text: 'Wow, de har X-factor', points: 3},
			{id: 4, text: 'Uheldigt interview, men forestillingen lyder spændende', points: 1},
			{id: 5, text: 'Cringe, men på den fede måde', points: 2},
			{id: 6, text: 'Yes, premieren kan kun komme for langsomt', points: 3},
			{id: 7, text: 'Gode tanker, men måske ikke lige mig', points: 1},
			{id: 8, text: 'Med det hold kan det kun blive.... emo', points: 2},
		],
		nextStepBtnText: '3. AKT'
	},
	{
		id: 'intro-3',
		actId: 'act-3',
		facilitatorComponent: Intro,
		groupComponent: Attention,
		title: 'Intro',
		groupInfo: 'Følg med på lærerskærmen',
		videoId: '1lQHa2Tj1p0'
	},
	{
		id: 'stage-walk',
		actId: 'act-3',
		facilitatorComponent: Assignment,
		groupComponent: Assignment,
		showDownloadExample: true,
		title: 'Scenegang',
		facilitatorInstructions: `Giv grupperne en time til halvanden til at skrive en scene til deres stykke og forberede sig på at opføre den som en reading. 
			<br /><br />Klik <a download href="/files/manuskript-eksempel.docx">HER</a> for at vise eleverne et eksempel på et manuskript med replikker og regi-bemærkninger.
			<br /><br />Scenerne skal blot fylde 1-2 sider.`,
		assignmentTitle: 'Opgave 3',
		assignmentSubtitle: 'Scenegang',
		assignmentText: `Publikum ved at forestillingen er på vej, og mange har allerede købt billetter. Så I må hellere komme i gang med at øve.
			<br /><br />Jeres opgave er nu at skrive en kort scene til jeres stykke. Der skal både være replikker og <b><i>regi-bemærkninger</i></b>. Scenen må max fylde tre sider.
			<br /><br />Når I har skrevet scenen, skal I fremføre den som en <b><i>reading</i></b>. Det er en tekstpræsentation, hvor skuespillerne står op og læser et stykke højt for et mindre indbudt publikum. Instruktøren læser regibemærkningerne.
			<br /><br />Husk at øve scenen, inden I opfører den.`
	},
	{
		id: 'performance',
		actId: 'act-3',
		type: 'presentation',
		facilitatorComponent: Presentation,
		groupComponent: Presentation,
		showAudience: true,
		title: 'Reading',
		facilitatorInstructions: `Grupperne skal nu opføre deres scener som en reading.
			<br /><br />Regi-bemærkningerne skal også læses op. 
			<br /><br />Vælg den gruppe der skal opføre først.`,
		facilitatorInstructions2: `Grupperne skal nu opføre deres scener som en reading.
			<br /><br />Regi-bemærkningerne skal også læses op. 
			<br /><br />Vælg den næste gruppe der skal opføre deres scene.`,
		facilitatorInstructions3: `Klik videre til bedømmelse efter opførelsen.`,
		facilitatorInstructions4: `Du og de andre grupper skal nu bedømme opførelsen. I skal vælge to udsagn. 
			<br /><br />Klik videre, når alle grupper har bedømt.`,
		feedbackOptions: [
			{id: 1, text: 'Overraskende...', points: 1},
			{id: 2, text: 'Lige så godt som jeg havde forestillet mig det', points: 2},
			{id: 3, text: 'Det bedste teater jeg nogensinde har set', points: 4},
			{id: 4, text: 'Jeg tissede i bukserne af grin', points: 3},
			{id: 5, text: 'Det skal min klasse se!', points: 2},
			{id: 6, text: 'I kan få min mobil hvis jeg må se det igen', points: 3},
			{id: 7, text: 'Nice moves', points: 1},
			{id: 8, text: 'Det er kunst!', points: 2},
		],
		nextStepBtnText: 'Afslutning'
	},
	{
		id: 'reviews',
		actId: null,
		facilitatorComponent: Finish,
		groupComponent: Finish,
		title: 'Anmeldelser',
		facilitatorInstructions: `Læs anmeldelsen af årets forestilling højt for klassen.
			<br /><br />Vinderforestillingen er fundet ud fra elevernes valg af stykke, genre og cast, samt hvordan de er blevet bedømt af deres klassekammerater.
			<br /><br />Klik derefter videre for at se anmeldelser af alle stykker.`,
		facilitatorInstructions2: `Alle grupper kan nu se en anmeldelse af deres forestilling på deres computer. 
			<br /><br />Bed dem læse deres anmeldelse højt for klassen. Alternativt kan du selv læse anmeldelserne højt.`,
		facilitatorInstructions3: `Klik `,
		facilitatorInstructions4: `HER`,
		facilitatorInstructions5: ` for at downloade alle anmeldelserne, så I kan printe dem ud og sætte op i klassen.`,
		facilitatorInstructions6: `Tak for spillet, vi håber I har haft en god oplevelse!
		<br /><br />Sæt Scenen! er skabt i 2022 af CPH Game Lab på initiativ af Dansk Teater 300 År og distribueret til samtlige skoler i Danmark.
		<br /><br />Vi takker A.P. Møller Fonden for velvillig støtte til projektet og Malte Odderskjær Barslund og hans elever på Lindehøjskolen i Herlev for uvurderlig faglig støtte.
		<br /><br /><br /><em>»Vi er af samme stof, som drømme gøres af«</em><br /><i>- Shakespeare</i>
		`
	}
];

export {
	gameStepsData
};