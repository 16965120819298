import {useState, useEffect} from 'react';
import firebaseInit from 'firebase-init';
import LandingPage from './landing-page/landing-page';
import LoginController from 'components/login/login-controller';
import ImageLoader from 'components/ui/image-loader/image-loader';
import './app.scss';

const App = () => {

	const [showLandingPage, setShowLandingPage] = useState(true);

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});


	return (
		<div className="App">
			<div className='App-wrap'>
				{/* <LoginController /> */}
				{showLandingPage 
					? <LandingPage setShowLandingPage={setShowLandingPage} /> 
					: <LoginController/>
				}
				<ImageLoader />
			</div>
		</div>
	);
};



export default App;
