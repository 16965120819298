import {actsData} from 'data/acts-data';
import {gameStepsData} from 'data/game-steps-data';
import {groupsData} from 'data/groups-data';
import {showsData} from 'data/shows-data';
import {genresData} from 'data/genres-data';
import {instructors, scenographers, actors} from 'data/cast-data';
import {reviewsData} from 'data/reviews-data';
import {sortArrayByProperty} from './array-helper';
import {getPresentationData, getFeedbackResult} from './presentation-helper';

/**
 * Get group points and stars
 * @param {object} game 
 * @param {array} activeGroups 
 * @returns 
 */
function getGroupStars(game, activeGroups) {

	/* Get group points */
	let groupPoints = activeGroups.map((group) => {
		let points = 0;
		actsData.forEach((act) => {
			let presentationData = getPresentationData(act.id, game);
			if (presentationData) {
				presentationData.feedbackStatus = 'closed';
				presentationData.selectedGroupId = group.id;
			}
			const gameStepData = gameStepsData.find((gs) => {
				return (gs.actId === act.id && gs.type === 'presentation');
			});
			const feedbackOptions = gameStepData.feedbackOptions;
			const feedbackResult = getFeedbackResult(act.id, feedbackOptions, presentationData, activeGroups);
			feedbackResult.forEach((feedback) => {				
				const feedbackData = feedbackOptions.find((f) => {return f.id === feedback.id;});
				if (feedback.selectedBy && feedbackData && feedbackData.points) {
					points += feedback.selectedBy * feedbackData.points;
				}
			});
		});
		return {...group, points: points};
	});

	/* Order groups by points */
	groupPoints = sortArrayByProperty(groupPoints, 'points', 'DESC');
	
	/* Get stars data for number of active groups */
	const starsData = reviewsData.stars.find((starData) => {
		return starData.numberOfGroups === Math.max(groupPoints.length, 3);
	});
	

	/* Get group points */
	groupPoints.forEach((gp, index) => {
		let numberOfGroupsInSameReviewGroup = 0;
		if (index === 0) {
			gp.stars = starsData.stars[index];
			gp.reviewIndex = 0;
		} else {
			if (gp.points === groupPoints[index - 1].points) {
				/* Tie breaker */
				gp.stars = groupPoints[index - 1].stars;
			} else {
				gp.stars = starsData.stars[index];
			}

			if (
				gp.stars === groupPoints[index - 1].stars ||
				(gp.stars === 5 && groupPoints[index - 1].stars === 6) ||
				(gp.stars === 3 && groupPoints[index - 1].stars === 4)
			) {
				numberOfGroupsInSameReviewGroup += 1;
				gp.reviewIndex = numberOfGroupsInSameReviewGroup;
			} else {
				gp.reviewIndex = 0;
			}
		}

		/* 0 points -> 3 stars */
		if (gp.points === 0) {
			gp.stars = 3;
		}
	});

	return groupPoints;
}

/**
 * Find winning group id
 * @param {object} game 
 * @param {array} groups 
 * @returns 
 */
function findWinningGroupId(game, groups) {
	/* Default winner id */
	let winnerId = game.id + '-1';

	/* Get stars of each group */
	const activeGroups = groups.filter((g) => {return g.isPlaying === true;});

	if (activeGroups.length > 0) {
		let groupStars = getGroupStars(game, activeGroups);
	
		/* Find max number of stars */
		groupStars = sortArrayByProperty(groupStars, 'points', 'DESC');
		const maxStars = (groupStars.length > 0 ? groupStars[0].stars : 0);
	
		/* Get all groups that have max number of stars */
		const groupsWithMaxStars = groupStars.filter((g) => {return g.stars === maxStars;});
	
		/* Find (random) winner */
		const randomIndex = Math.floor(Math.random() * groupsWithMaxStars.length);
		winnerId = groupsWithMaxStars[randomIndex].id;
	}


	return winnerId;
};


/**
 * Get names of group, show, genre and cast
 * @param {object} group 
 * @returns 
 */
function getNames(group) {
	/* Group name */
	const groupName = groupsData.find((g) => {return g.id === group.groupId;}).name;

	/* Show, genre, instructor, scenographer and actor names */
	const showName = (group.showId ? showsData.find((s) => {return s.id === group.showId;}).value : null);
	const genreName = (group.genreId ? genresData.find((g) => {return g.id === group.genreId;}).value : null);

	const instructorName = (group.instructorId 
		? instructors.find((i) => {return i.id === group.instructorId;}).name 
		: null
	);

	const instructorNamePossessive = (instructorName
		? instructorName + (instructorName.slice(-1) === 's' ? '\'' : 's')
		: null
	);


	const scenographerName = (group.scenographerId 
		? scenographers.find((i) => {return i.id === group.scenographerId;}).name 
		: null
	);
	const actor1 = (group.actors && group.actors.length > 0 
		? group.actors.find((a) => {return a.roleId === 1;})
		: null
	);
	const actor1Name = (actor1 
		? actors.find((i) => {return i.id === actor1.actorId;}).name 
		: null
	);

	const actor2 = (group.actors && group.actors.length > 0 
		? group.actors.find((a) => {return a.roleId === 2;})
		: null
	);
	const actor2Name = (actor2 
		? actors.find((i) => {return i.id === actor2.actorId;}).name 
		: null
	);

	const actor3 = (group.actors && group.actors.length > 0 
		? group.actors.find((a) => {return a.roleId === 3;})
		: null
	);
	const actor3Name = (actor3
		? actors.find((i) => {return i.id === actor3.actorId;}).name 
		: null
	);

	return {
		groupName,
		showName,
		genreName,
		instructorName,
		instructorNamePossessive,
		scenographerName,
		actor1Name,
		actor2Name,
		actor3Name
	};
}

/**
 * Replace dynamic text parts
 * @param {string} text 
 */
function replaceDynamicText(text, names, numberOfStars) {
	
	const newText = text
		.replace('%groupName%', names.groupName)
		.replace('%showName%', names.showName)
		.replace('%genreName%', names.genreName)
		.replace('%instructorName%', names.instructorName)
		.replace('%instructorNamePossessive%', names.instructorNamePossessive)
		.replace('%scenographerName%', names.scenographerName)
		.replace('%actor1Name%', names.actor1Name)
		.replace('%actor2Name%', names.actor2Name)
		.replace('%actor3Name%', names.actor3Name)
		.replace('%numberOfStars', numberOfStars);

	
	return newText;
};

/**
 * Get custom text for main part 1/2/3
 * @param {number} part 
 * @param {object} group 
 * @returns 
 */
function getMainPartText(part, group) {
	let mainPartText = null;
	let options = [];

	if (part === 1) {
		const starGroup = reviewsData['mainPart' + part].find((mp) => {
			return (mp.numberOfStars.indexOf(group.stars) >= 0);
		});
		if (starGroup && starGroup.options) options = starGroup.options;
	} else {
		options = reviewsData['mainPart' + part];
	}

	options.forEach((option) => {
		if (mainPartText) return;
		if (checkIfConditionsAreMet(option.conditions, group)) {
			mainPartText = option.text;
		}
	});
	

	return mainPartText;
};

/**
 * Check if group fullfills all required conditions
 * @param {array} conditions 
 * @param {object} group 
 * @returns 
 */
function checkIfConditionsAreMet(conditions, group) {
	let conditionsAreMet = true;
	conditions.forEach((condition) => {
		if (!conditionsAreMet) return;

		/* Condition: "property is" */
		if (
			condition.type === 'propertyIs' &&
			group[condition.propertyName] !== condition.propertyValue
		) {
			conditionsAreMet = false;
		}

		/* Condition: "property is NOT" */
		if (
			condition.type === 'propertyIsNot' &&
			group[condition.propertyName] === condition.propertyValue
		) {
			conditionsAreMet = false;
		}
	});

	return conditionsAreMet;
};

export {
	getGroupStars,
	findWinningGroupId,
	getNames,
	replaceDynamicText,
	getMainPartText
};