/**
 * Check if any group has selected specific value of property
 * @param {string} property 
 * @param {string} value 
 * @param {array} groups 
 * @returns 
 */
function checkIfOptionIsSelectedByAnyGroup(property, value, groups) {
	return groups.some((g) => {
		return (g.hasOwnProperty(property) && g[property] === value);
	});
}

function getGroupIdsOfGroupWithSelectedOption(property, value, groups) {
	const groupIds = groups.filter((g) => {
		return (g.hasOwnProperty(property) && g[property] === value);
	}).map((g) => {return g.groupId;});

	return groupIds;
}

/**
 * Check if any group has an array with the specific value of property
 * @param {string} property 
 * @param {string} value 
 * @param {array} groups 
 * @returns 
 */
function checkIfOptionIsSelectedByAnyGroupArrays(arrayName, property, value, groups) {
	return groups.some((g) => {
		return (
			g.hasOwnProperty(arrayName) && 
			g[arrayName].length > 0 &&
			g[arrayName].some((gp) => {return gp[property] === value;})
		);
	});
}

export {
	getGroupIdsOfGroupWithSelectedOption,
	checkIfOptionIsSelectedByAnyGroup,
	checkIfOptionIsSelectedByAnyGroupArrays
};