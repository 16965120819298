import ReactDOMServer from 'react-dom/server';
import {gameUiTexts} from 'data/ui-texts';
import ReviewPrint from 'components/ui/review-print/review-print';
/**
 * Print the material needed for all selected activities
 * @param {array} chapters 
 * @param {array} selectedActivityIds 
 */
function handlePrintReviews(groups) {
	let html = [];
	groups.forEach((group, index) => {
		html.push(ReactDOMServer.renderToStaticMarkup(
			<ReviewPrint isLastGroup={index + 1 === groups.length} group={group} />
		));
	});
	printHelper(html);
}


/**
 * Opens new tab with print content and triggers the print window
 * @param {string} html 
 * @param {string} type 
 * @param {bool} pageBreak
 */
function printHelper(html) {
	const documentTitle = gameUiTexts.gameTitle + ' - ' + gameUiTexts.reviews;
	// let logo = require('assets/images/logo-blivnaturligvis.svg');
	// let logo2 = require('assets/images/logo-blivnaturligvis2.png');
	// let footerContent = '<span>Find mere inspiration på</span><img src="' + logo + '" alt="logo"/>';
	// let safariLogo = '<img src="' + logo2 + '" alt="logo"/>';
	// let browserName = getOSAndBrowserInfo()[1];

	var myWindow = window.open();
	myWindow.document.write('<html><head>');
	myWindow.document.write('<title>' + documentTitle + '</title>');
	// myWindow.document.write('<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,700" rel="stylesheet">');
	myWindow.document.write('<link href="/review-print/review-print.css" rel="stylesheet">');
	myWindow.document.write('</head>');
	myWindow.document.write('<body>');
	// if (browserName === 'safari') myWindow.document.write('<div class="circleLogo">' + safariLogo + '</div>');
	// myWindow.document.write('<table class="content">');
	// myWindow.document.write('<tbody><tr><td>');
	html.forEach((content) => {myWindow.document.write(content);});
	// myWindow.document.write('</td></tr>');
	// myWindow.document.write('<tfoot><tr><td><div class="footer-space"></div></td></tr></tfoot>');
	// myWindow.document.write('</table>');
	// if (browserName !== 'safari') myWindow.document.write('<div class="footer">' + footerContent + '</div>');
	myWindow.document.write('</body>');
	myWindow.document.write('</html>');
	myWindow.document.close();
	myWindow.focus();
	myWindow.addEventListener('load', () => {setTimeout(() => {myWindow.print();}, 200);});
};

export {
	handlePrintReviews
};