import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {gameUiTexts} from 'data/ui-texts';
import {actors} from 'data/cast-data';
import Button from 'components/ui/button/button';
import './casting-popup.scss';

const CastingPopup = ({actorId, selectedShowData, updateSpecialGroupProperty, setPopupActorId}) => {
	/* Actor data */
	const actorData = actors.find((a) => {return a.id === actorId;});

	/* Selected role id */
	const [roleId, setRoleId] = useState(null);

	/* Track if updating selected actor/role */
	const [isUpdating, setIsUpdating] = useState(false);

	/* Confirm actor & role */
	const handleConfirm = () => {
		setIsUpdating(true);
		updateSpecialGroupProperty('actors', actorId, roleId).then(() => {
			setPopupActorId(null);
		}).catch((error) => {
			console.error(error);
			setPopupActorId(null);
		});
	};

	/* Get popup text */
	let popupText = JSON.parse(JSON.stringify(gameUiTexts.whichRole));
	popupText = popupText.replace('%ACTOR%', actorData.name);

	return (
		<div className="CastingPopup">
			<div className="CastingPopup-content">
				<div className='CastingPopup-closeButton' onClick={() => {setPopupActorId(null);}}/>
				<div className="CastingPopup-title"><span>{gameUiTexts.chooseRole}</span></div>
				<div className="CastingPopup-text"><span>{popupText}</span></div>
				<div className="CastingPopup-roles">
					{selectedShowData && selectedShowData.roles.map((role) => {
						return (
							<div 
								key={role.id} 
								className={'CastingPopup-role' + (roleId === role.id ? ' selected' : '')}
								onClick={() => {setRoleId(role.id);}}
							>
								<span>{role.title}</span>
							</div>
						);
					})}
				</div>
				<div className="CastingPopup-confirmBtn">
					<Button 
						isDisabled={!roleId}
						isLoading={isUpdating}
						classes={['yellowGradient']}
						text={gameUiTexts.choose}
						onClick={() => {handleConfirm();}}
					/>
				</div>
			</div>	
		</div>
	);
};

CastingPopup.propTypes = {
	actorId: PropTypes.number.isRequired,
	selectedShowData: PropTypes.object,
	updateSpecialGroupProperty: PropTypes.func.isRequired,
	setPopupActorId: PropTypes.func.isRequired
};

export default CastingPopup;
