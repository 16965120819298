import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import {statsUiTexts} from 'data/ui-texts';
import Loading from 'components/loading/loading';
import Stats from 'components/stats/stats';


class AdminController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			userData: null,
			users: [],
			games: [],
			groups: []
		};
		this.timeout = null;
		this.unsubscribeUser = null;
		this.unsubscribeUsers = null;
		this.unsubscribeGames = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Get user data */
		this.subscribeToUser().then((response) => {
			if (response.status === 'success') {
				if (this.state.userData && this.state.userData.isAdmin === true) {
					/* Subscribe to users */
					Promise.all([
						this.subscribeToUsers(),
						this.subscribeToGames(),
						this.getGroups()
					]).then(() => {
						this.setState({isLoading: false});
					});
				} else {
					/* User is not admin */
					window.location.href = '/';
				}
			} else {
				this.setState({isLoading: false});
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);

		/* Unsubscribe */
		if (this.unsubscribeUser !== null) this.unsubscribeUser();
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
	};

	/**
	 * Subscribe to user (facilitator) data
	 */
	subscribeToUser = () => {
		if (this.unsubscribeUser !== null) this.unsubscribeUser();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribeUser = db.collection(appConfig.usersDbName).doc(this.props.userId).onSnapshot((doc) => {
				if (doc.exists) {
					/* Get user data */
					let userData = {id: doc.id, ...doc.data()};

					/* Update state */
					this.setState({userData}, () => {resolve({ status: 'success' });});
				} else {
					console.error('user data not found');
					resolve({ status: 'error', error: 'user data not found'});
					this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
				}
			},
			(error) => {
				console.error('could not get user: ', error);
				resolve({ status: 'error', error: error});
				this.timeout = setTimeout(() => {this.props.handleLogout();}, 5000);
			});
		});
	};

	/**
	 * Subscribe to all users
	 */
	subscribeToUsers = () => {
		if (this.unsubscribeUsers !== null) this.unsubscribeUsers();
		
		return new Promise((resolve) => {
			let db = firebase.firestore();
			this.unsubscribeUsers = db.collection(appConfig.usersDbName).onSnapshot(
				(querySnapshot) => {
					let users = [];
					querySnapshot.forEach((doc) => {users.push({id: doc.id, ...doc.data()});});
					this.setState({ users: users }, () => {
						resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Subscribe to games
	 */
	subscribeToGames = () => {
		if (this.unsubscribeGames !== null) this.unsubscribeGames();
		
		return new Promise((resolve) => {
			let db = firebase.firestore();
			this.unsubscribeGames = db.collection(appConfig.gamesDbName).onSnapshot(
				(querySnapshot) => {
					let games = [];
					querySnapshot.forEach((doc) => {games.push({id: doc.id, ...doc.data()});});
					this.setState({ games: games }, () => {
						resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get games: ', error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Get groups
	 * @returns 
	 */
	getGroups = () => {
		return new Promise((resolve) => {
			let db = firebase.firestore();
			db.collection(appConfig.groupsDbName).get().then(
				(docs) => {
					let groups = [];
					docs.forEach((doc) => {groups.push({id: doc.id, ...doc.data()});});
					this.setState({ groups: groups }, () => {
						resolve({status: 'success'});
					});
				},
				(error) => {
					console.error('could not get groups: ', error);
					resolve({status: 'error', error: error});
				}
			);
		});
	};

	/**
	 * Render component
	 */
	render() {
		/* Loading */
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={statsUiTexts.loadingStats}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};

		/* Game */
		return (
			<Stats 
				userData={this.state.userData} 
				users={this.state.users} 
				games={this.state.games}
				groups={this.state.groups}
				handleLogout={this.props.handleLogout} 
			/>
		);

	}
}

AdminController.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default AdminController;
