import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {loginUiTexts, errorUiTexts} from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './reset-password.scss';

const ResetPassword = ({setFacilitatorBoxType}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [passwordIsReset, setPasswordIsReset] = useState(false);
	const [email, setEmail] = useState('');
	const [feedback, setFeedback] = useState(null);

	/**
	 *  Update input field
	 */
	const handleInput = (event) => {
		let value = event.target.value;
		setEmail(value);
		setFeedback(null);
	};

	/** 
	 * Submit reset password request 
	 */
	const handleSubmit = () => {
		if (!email || email.length === 0) {
			setFeedback(errorUiTexts.invalidEmail);
			return;
		}
		handleResetPassword(email);
	};

	/** 
	 * Reset password 
	 */
	const handleResetPassword = (email) => {
		setIsLoading(true);

		const auth = firebase.auth();
		auth.sendPasswordResetEmail(email).then(()=>{
			setIsLoading(false);
			setPasswordIsReset(true);
		}).catch((error) =>{
			console.error(error);
			setIsLoading(false);
			setFeedback(errorUiTexts.unknownError);
		});
	};

	if (passwordIsReset) {
		return (
			<div className="ResetPassword">
				<div className="ResetPassword-title"><span>{loginUiTexts.facilitatorResetPw}</span></div>
				<div className="ResetPassword-body">
					<div className="ResetPassword-info"><span>{loginUiTexts.passwordIsReset}</span></div>
					<div 
						className="ResetPassword-resetPasswordBtn"
						onClick={()=>{setFacilitatorBoxType('login');}}
					><span>{loginUiTexts.backToLogin}</span></div>
				</div>
			</div>
		);
	}

	return (
		<div className="ResetPassword">
			<div className="ResetPassword-title"><span>{loginUiTexts.facilitatorResetPw}</span></div>
			<div className="ResetPassword-body">
				<form className="ResetPassword-form" onSubmit={handleSubmit}>
					<input
						name="email"
						type="text"
						placeholder={loginUiTexts.email}
						autoComplete="section-resetpasswrd email"
						value={email ? email : ''}
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button
						isLoading={isLoading}
						classes={['login']}
						text={loginUiTexts.reset}
						onClick={() =>{handleSubmit();}}
					/>
					<p className="ResetPassword-errorMessage">{feedback}</p>
				</form>
				<div 
					className="ResetPassword-resetPasswordBtn"
					onClick={()=>{setFacilitatorBoxType('login');}}
				><span>{loginUiTexts.backToLogin}</span></div>
			</div>
		</div>
	);
};

ResetPassword.propTypes = {
	setFacilitatorBoxType: PropTypes.func.isRequired,
};

export default ResetPassword;